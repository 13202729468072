import React from 'react';
import { LoadingPanel } from '../shared/LoadingPanel';
import { Translate } from '../shared/Translate';

const LoadingMessage = ({ i18nKeyMessage }) => {
  return (
    <>
      <h1>
        <Translate i18nKey={i18nKeyMessage}></Translate>
      </h1>
      <LoadingPanel idPortal=".main-authorization-container"></LoadingPanel>
    </>
  );
};

export { LoadingMessage };
