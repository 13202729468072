import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ConditionsViewer } from '..';
import './policyAndPrivacy.scss';

export function PolicyAndPrivacy(props) {
  const [t] = useTranslation();
  const [show, setShow] = useState(false);
  const [isPolicy, setIsPolicy] = useState(false);

  const onPolicyHandler = () => {
    setIsPolicy(true);
    setShow(true);
  };

  const onConditionsHandler = () => {
    setIsPolicy(false);
    setShow(true);
  };

  const hideModal = () => {
    setShow(false);
  };

  return (
    <>
      {props.isFooter ? (
        <div>
          <span className="policyAndPrivacy__link" onClick={onPolicyHandler}>
            {t('common.policy')}
          </span>
          <span className="policyAndPrivacy__link" onClick={onConditionsHandler}>
            {t('common.conditions')}
          </span>
        </div>
      ) : (
        <div className="policyAndPrivacy__text">
          <p className="small-text">
            {t('information.text1')}
            <span className="policyAndPrivacy__link policyAndPrivacy__link-small" onClick={onPolicyHandler}>
              {t('common.policy')}
            </span>
            {t('information.text3')}
          </p>
        </div>
      )}

      <ConditionsViewer show={show} onClose={hideModal} isPolicy={isPolicy} />
    </>
  );
}

PolicyAndPrivacy.propTypes = {
  isFooter: PropTypes.bool
};
