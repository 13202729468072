import React from 'react';
import { useTranslation } from 'react-i18next';
import { Field } from '@progress/kendo-react-form';
import { FormInput, FormDatePicker, FormDropDownList } from '~/components/shared/form-fields';
import { emailValidator } from '~/components/shared/form-validators';

const FormRepresentativeElement = ({ typesID }) => {
  const { t } = useTranslation();

  return (
    <>
      <p className="health-data-subtitle">
        <b>{t('platform.requestDataset.titleRepresentative')}</b>
        <br></br>
        <span className="health-data-subtitle description">
          {t('platform.requestDataset.descriptionRepresentative')}
        </span>
      </p>
      <div className="mb-3">
        <Field
          name={'representative.name'}
          component={FormInput}
          label={t('platform.requestDataset.fields.representative.name')}
        />
      </div>
      <div className="mb-3">
        <Field
          name={'representative.title'}
          component={FormInput}
          label={t('platform.requestDataset.fields.representative.title')}
        />
      </div>
      <div className="mb-3">
        <Field
          name={'representative.position'}
          component={FormInput}
          label={t('platform.requestDataset.fields.representative.position')}
        />
      </div>
      <div className="mb-3">
        <Field
          name={'representative.affiliation'}
          component={FormInput}
          label={t('platform.requestDataset.fields.representative.affiliation')}
        />
      </div>
      <div className="mb-3">
        <Field
          name={'representative.institutionalEmail'}
          component={FormInput}
          label={t('platform.requestDataset.fields.representative.institutionalEmail')}
          validator={emailValidator}
        />
      </div>
      <div className="mb-3">
        <Field
          name={'representative.address'}
          component={FormInput}
          label={t('platform.requestDataset.fields.representative.address')}
        />
      </div>
      <fieldset className={'k-form-fieldset'}>
        <legend className={'k-form-legend'}>
          {t('platform.requestDataset.fields.representative.identificationDocumentLegend')}
        </legend>
        <div className="mb-3">
          <Field
            key={'representative.identificationDocument'}
            id={'representative.identificationDocument'}
            name={'representative.identificationDocument'}
            label={t('platform.requestDataset.fields.representative.identificationDocument')}
            component={FormDropDownList}
            data={typesID}
            textField={'name'}
            valueField={'id'}
          />
        </div>
        <div className="mb-3">
          <Field
            name={'representative.identificationNumber'}
            component={FormInput}
            label={t('platform.requestDataset.fields.representative.identificationNumber')}
          />
        </div>
        <div className="mb-3">
          <Field
            name={'representative.countryID'}
            component={FormInput}
            label={t('platform.requestDataset.fields.representative.countryID')}
          />
        </div>
        <div className="mb-3">
          <Field
            name={'representative.dateOfIssue'}
            component={FormDatePicker}
            label={t('platform.requestDataset.fields.representative.dateOfIssue')}
          />
        </div>
        <div className="mb-3">
          <Field
            name={'representative.dueDate'}
            component={FormDatePicker}
            label={t('platform.requestDataset.fields.representative.dueDate')}
          />
        </div>
      </fieldset>
    </>
  );
};

export { FormRepresentativeElement };
