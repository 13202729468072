import React from 'react';
import { useTranslation } from 'react-i18next';
import { Field } from '@progress/kendo-react-form';
import { FormInput, FormTextArea } from '~/components/shared/form-fields';
import { urlValidator, maxLength1000Validator, maxLength250Validator } from '~/components/shared/form-validators';

const FormProjectElement = () => {
  const { t } = useTranslation();
  return (
    <>
      <p className="health-data-subtitle">
        <b>{t('platform.requestDataset.titleProject')}</b>
        <br></br>
        <span className="health-data-subtitle description">{t('platform.requestDataset.descriptionProject')}</span>
      </p>
      <div className="mb-3">
        <Field name={'projectTitle'} component={FormInput} label={t('platform.requestDataset.fields.projectTitle')} />
      </div>
      <div className="mb-3">
        <Field
          name={'projectWeb'}
          component={FormInput}
          label={t('platform.requestDataset.fields.projectWeb')}
          validator={urlValidator}
        />
      </div>
      <p className="health-data-subtitle">
        <b>{t('platform.requestDataset.titleResearchTrack')}</b>
        <br></br>
        <span className="health-data-subtitle description">
          {t('platform.requestDataset.descriptionResearchTrack')}
        </span>
      </p>
      <div className="mb-3">
        <Field
          name={'researchTrack'}
          component={FormTextArea}
          rows={4}
          label={t('platform.requestDataset.fields.researchTrack')}
          validator={maxLength1000Validator}
        />
      </div>
      <p className="health-data-subtitle">
        <b>{t('platform.requestDataset.titleResearchProject')}</b>
        <br></br>
        <span className="health-data-subtitle description">
          {t('platform.requestDataset.descriptionResearchProject')}
        </span>
      </p>
      <div className="mb-3">
        <Field
          name={'researchProject'}
          component={FormTextArea}
          rows={6}
          label={t('platform.requestDataset.fields.researchProject')}
          validator={maxLength1000Validator}
        />
      </div>
    </>
  );
};

export { FormProjectElement };
