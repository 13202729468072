import { useToasts } from 'react-toast-notifications';

export function useNotification() {
  const { addToast } = useToasts();

  function error(message) {
    addToast(message, { appearance: 'error' });
  }

  function success(message) {
    addToast(message, { appearance: 'success' });
  }

  return [error, success];
}
