import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { getCurrentLanguageLocale } from '~/services/app.i18n';
import { Field } from '@progress/kendo-react-form';
import { ConditionsViewer } from '~/components/shared/ConditionsViewer';
import { FormCheckboxTerms } from '~/components/shared/form-fields';
import { acceptTermsValidator, acceptPrivacyValidator } from '~/components/shared/form-validators';
import './FormAcceptTermsElement.scss';

const FormAcceptTermsElement = ({ dataItem, formRenderProps }) => {
  const { t } = useTranslation();

  const [show, setShow] = useState(false);
  const [isPolicy, setIsPolicy] = useState(false);
  const accepTermsCheck = formRenderProps.valueGetter('acceptTerms');
  const accepPrivacyCheck = formRenderProps.valueGetter('acceptPrivacy');
  const accepPrivacyF29Check = formRenderProps.valueGetter('acceptPrivacyF29');

  useEffect(() => {
    if (accepTermsCheck) {
      if (dataItem?.termsFile?.length > 0) {
        window.open(dataItem.termsFile);
      } else {
        onConditionsHandler();
      }
    }
  }, [accepTermsCheck]);

  useEffect(() => {
    if (accepPrivacyCheck) {
      if (dataItem?.privacyFile?.length > 0) {
        window.open(dataItem.privacyFile);
      } else {
        onPolicyHandler();
      }
    }
  }, [accepPrivacyCheck]);

  useEffect(() => {
    if (accepPrivacyF29Check) {
      const currentLanguageCode = getCurrentLanguageLocale();
      window.open(`/api/file/download2?language=${currentLanguageCode}`);
    }
  }, [accepPrivacyF29Check]);

  const onPolicyHandler = () => {
    setIsPolicy(true);
    setShow(true);
  };

  const onConditionsHandler = () => {
    setIsPolicy(false);
    setShow(true);
  };

  const hideModal = () => {
    setShow(false);
  };

  return (
    <>
      {(dataItem?.termsFile?.length > 0 || dataItem?.privacyFile?.length > 0) && (
        <div className="small-text">
          <p>{t('platform.requestDataset.privacy1')}</p>
        </div>
      )}
      {dataItem?.privacyFile?.length > 0 && (
        <div className="mb-3">
          <Field
            name={'acceptPrivacy'}
            component={FormCheckboxTerms}
            label={t('platform.requestDataset.fields.acceptPrivacy')}
            validator={acceptPrivacyValidator}
            labelComponent={
              <span className="small-text">
                {t('platform.requestDataset.privacyFile1')}
                <span className="formAcceptTermsElement__link formAcceptTermsElement__link-small">
                  {t('platform.requestDataset.privacyFileLink')}
                </span>
                {t('platform.requestDataset.privacyFile2')}
              </span>
            }
          />
        </div>
      )}
      <div className="mb-3">
        <Field
          name={'acceptPrivacyF29'}
          component={FormCheckboxTerms}
          label={t('platform.requestDataset.fields.acceptPrivacy')}
          validator={acceptPrivacyValidator}
          labelComponent={
            <span className="small-text">
              {t('platform.requestDataset.privacyFile1')}
              <span className="formAcceptTermsElement__link formAcceptTermsElement__link-small">
                {t('platform.requestDataset.privacyFileF29Link')}
              </span>
              {t('platform.requestDataset.privacyFile2')}
            </span>
          }
        />
      </div>
      {dataItem?.termsFile?.length > 0 && (
        <div className="mb-3">
          <Field
            name={'acceptTerms'}
            component={FormCheckboxTerms}
            label={t('platform.requestDataset.fields.acceptTerms')}
            validator={acceptTermsValidator}
            labelComponent={
              <span className="small-text">
                {t('platform.requestDataset.terms1')}
                <span className="formAcceptTermsElement__link formAcceptTermsElement__link-small">
                  {t('platform.requestDataset.termsLink')}
                </span>
                {t('platform.requestDataset.terms2')}
              </span>
            }
          />
        </div>
      )}
      <div className="mb-3">
        <Field
          name="ReceiveCommercial"
          component={FormCheckboxTerms}
          label={t('platform.requestDataset.fields.acceptPrivacy')}
          labelComponent={<span className="small-text">{t('platform.requestDataset.receiveCommercial')}</span>}
        />
      </div>
      <ConditionsViewer show={show} onClose={hideModal} isPolicy={isPolicy} isRequestsViewer={true} />
    </>
  );
};

export { FormAcceptTermsElement };
