import React from 'react';

const RequestViewFormField = ({ label, value }) => {
  return (
    <div className="mb-3">
      <div>
        <span className="k-textbox-container">
          <input className="k-textbox" value={value || ''} readOnly></input>
          <label className="k-label">{label}</label>
        </span>
      </div>
    </div>
  );
};

export { RequestViewFormField };
