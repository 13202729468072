import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import aspCookie from './aspCookieLanguageDetector';

// English
import en from '~/assets/locales/en.json';
// Spanish
//import es from '~/assets/locales/es.json';

export const language = {
  English: {
    code: 'en-US',
    locale: 'en',
    available: true
  },
  Spanish: {
    code: 'es-ES',
    locale: 'es',
    available: false
  }
};

const languageList = [{ ...language.English }, { ...language.Spanish }];

const defaultLanguage = language.English;

const resources = {
  [language.English.code]: {
    translation: en
  }
  //[language.Spanish.code]: {
  //  translation: es
  //}
};

const options = {
  // order and from where user language should be detected
  order: ['aspCookie'],
  caches: ['aspCookie'],
  defaultLanguage: defaultLanguage.code
};

const languageDetector = new LanguageDetector();
languageDetector.addDetector(aspCookie);

i18n
  .use(languageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    detection: options,
    resources,
    interpolation: {
      escapeValue: false // react already safes from xss
    },
    fallbackLng: defaultLanguage.code
  });

export default i18n;

export function changeCurrentLanguage(lang) {
  i18n.changeLanguage(lang);
}

function getLanguageByCode(code) {
  return languageList.find(l => l.code.toLowerCase() === code.toLowerCase());
}

function getCurrentLanguage() {
  return getLanguageByCode(i18n.language) ?? defaultLanguage;
}

export function getCurrentLanguageCode() {
  return getCurrentLanguage().code;
}

export function getCurrentLanguageLocale() {
  return getCurrentLanguage().locale;
}

export function getNumberOfLanguagesAvailables() {
  return languageList.find(l => l.available).length;
}

export function getDefaultLanguage() {
  return defaultLanguage;
}

export function getLanguageList() {
  return languageList;
}
