import React from 'react';
import { useTranslation } from 'react-i18next';
import { RequestViewFormField } from './RequestViewFormField';

const RequestViewPersonalData = ({ name, dataItem }) => {
  const { t } = useTranslation();

  return (
    <>
      {dataItem && (
        <fieldset className={'k-form-fieldset'}>
          <legend className={'k-form-legend'}>{t(`platform.petition.${name}`)}</legend>
          <RequestViewFormField label={t(`platform.petition.fields.${name}.name`)} value={dataItem.name} />
          <RequestViewFormField label={t(`platform.petition.fields.${name}.title`)} value={dataItem.title} />
          <RequestViewFormField label={t(`platform.petition.fields.${name}.position`)} value={dataItem.position} />
          <RequestViewFormField
            label={t(`platform.petition.fields.${name}.affiliation`)}
            value={dataItem.affiliation}
          />
          <RequestViewFormField
            label={t(`platform.petition.fields.${name}.institutionalEmail`)}
            value={dataItem.institutionalEmail}
          />
          <RequestViewFormField label={t(`platform.petition.fields.${name}.address`)} value={dataItem.address} />
          <RequestViewFormField
            label={t(`platform.petition.fields.${name}.identificationDocument`)}
            value={dataItem.identificationDocument && dataItem.identificationDocument.name}
          />
          <RequestViewFormField
            label={t(`platform.petition.fields.${name}.identificationNumber`)}
            value={dataItem.identificationNumber}
          />
          <RequestViewFormField label={t(`platform.petition.fields.${name}.countryID`)} value={dataItem.countryID} />
          <RequestViewFormField
            label={t(`platform.petition.fields.${name}.dateOfIssue`)}
            value={String.stringToLocaleDateString(dataItem.dateOfIssue)}
          />
          <RequestViewFormField
            label={t(`platform.petition.fields.${name}.dueDate`)}
            value={String.stringToLocaleDateString(dataItem.dueDate)}
          />
        </fieldset>
      )}
    </>
  );
};

export { RequestViewPersonalData };
