import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { useAction, HttpMethod } from '~/hooks/useAction';
import { urlBuilder } from '~/shared/constants';
import { getCurrentLanguageLocale } from '~/services/app.i18n';
import { LoadingPanel } from '~/components/shared/LoadingPanel';
import './publicDataset.scss';

const PublicDataset = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const history = useHistory();

  const getDownloadUrl = lang => {
    return `/api/file/download2?language=${lang}`;
  };

  const { executeAction, loading, response } = useAction(
    {
      httpMethod: HttpMethod.GET
    },
    false,
    false
  );

  const onSearch = () => {
    executeAction({ url: urlBuilder.datasets.getPublicDataset(id, getCurrentLanguageLocale()) });
  };

  useEffect(() => {
    setIfDatasetLanguageDistinct(false);
    onSearch();
  }, [t]);

  const [ifDatasetLanguageDistinct, setIfDatasetLanguageDistinct] = useState(false);
  useEffect(() => {
    if (response?.data) {
      const currentLanguageCode = getCurrentLanguageLocale();
      const dataset = response.data;
      const ifDistinct =
        dataset.nameLanguage !== currentLanguageCode ||
        dataset.descriptionLanguage !== currentLanguageCode ||
        dataset.metadataLanguage !== currentLanguageCode ||
        (dataset.privacyFileLanguage && dataset.privacyFileLanguage !== currentLanguageCode) ||
        (dataset.termsFileLanguage && dataset.termsFileLanguage !== currentLanguageCode);
      setIfDatasetLanguageDistinct(ifDistinct);
    }
  }, [response]);

  const getPublisherName = dataItem => {
    return `${dataItem.isAnInstitution ? dataItem.institution : 'Individual publisher'}`;
  };

  return (
    <div className="row justify-content-md-center loading-container-publicDataset">
      <div className="col-md-8">
        {loading && <LoadingPanel idPortal=".loading-container-publicDataset"></LoadingPanel>}
        {response && response.data && (
          <>
            <div className="k-form k-form-public-dataset">
              <fieldset className={'k-form-fieldset'}>
                <legend className={'k-form-legend'}>{t('platform.search.detail.dataset')}</legend>
                <div className="mb-3">
                  <div className="health-data-subtitle data-set-name">{response.data.name}</div>
                </div>
              </fieldset>
              <fieldset className={'k-form-fieldset'}>
                <legend className={'k-form-legend'}>{t('platform.search.detail.institution')}</legend>
                <div className="mb-3">
                  <div className="health-data-subtitle data-set">{getPublisherName(response.data)}</div>
                </div>
              </fieldset>
              <fieldset className={'k-form-fieldset'}>
                <legend className={'k-form-legend'}>{t('platform.search.detail.description')}</legend>
                <div className="mb-3">
                  <p>{response.data.description}</p>
                </div>
              </fieldset>
              <fieldset className={'k-form-fieldset'}>
                <legend className={'k-form-legend'}>{t('platform.search.detail.metadata')}</legend>
                <div className="mb-3">
                  <p>{response.data.metadata}</p>
                </div>
              </fieldset>
              {response?.data?.privacyFile && (
                <fieldset className={'k-form-fieldset'}>
                  <legend className={'k-form-legend'}>{t('platform.search.detail.privacyFile')}</legend>
                  <div className="mb-3">
                    <a href={response.data.privacyFile} target="_blank" rel="noopener noreferrer">
                      {t('platform.search.detail.downloadFile')}
                    </a>
                  </div>
                </fieldset>
              )}
              <fieldset className={'k-form-fieldset'}>
                <legend className={'k-form-legend'}>{t('platform.search.detail.privacyFile')} F29</legend>
                <div className="mb-3">
                  <a href={getDownloadUrl(getCurrentLanguageLocale())} target="_blank" rel="noopener noreferrer">
                    {t('platform.search.detail.downloadFile')}
                  </a>
                </div>
              </fieldset>
              {response?.data?.termsFile && (
                <fieldset className={'k-form-fieldset'}>
                  <legend className={'k-form-legend'}>{t('platform.search.detail.termsFile')}</legend>
                  <div className="mb-3">
                    <a href={response.data.termsFile} target="_blank" rel="noopener noreferrer">
                      {t('platform.search.detail.downloadFile')}
                    </a>
                  </div>
                </fieldset>
              )}
              {ifDatasetLanguageDistinct && (
                <div className="k-form-hint k-form-error k-text-start">
                  {t('platform.search.detail.languageNotAvailable')}
                </div>
              )}
              <div className="k-form-buttons">
                <button
                  className="health-data-button"
                  onClick={() => {
                    history.replace('/platform/datasets');
                  }}
                >
                  {t('platform.search.detail.return')}
                </button>
                <button
                  className="health-data-button"
                  onClick={() => {
                    history.push('/platform/datasets/request/' + response.data.id);
                  }}
                >
                  {t('platform.search.detail.request')}
                </button>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export { PublicDataset };
