import React, { useState } from 'react';
import { Grid } from '@progress/kendo-react-grid';
import { process } from '@progress/kendo-data-query';
import { KendoLocalizationProvider } from '../shared/KendoLocalizationProvider';
import { LoadingPanel } from '../shared/LoadingPanel';
import '@progress/kendo-theme-material/dist/all.css';
import './customGrid.scss';

const CustomGrid = ({ loading, data, children, sortable = true, filterable = true, className, ...props }) => {
  const [dataState, setDataState] = useState({
    skip: 0
  });

  return (
    <>
      <KendoLocalizationProvider>
        <Grid
          className={className}
          {...dataState}
          {...props}
          data={data && process([...data], dataState)}
          style={{ height: '660px' }}
          rowHeight={60}
          scrollable="virtual"
          pageSize={20}
          total={data ? data.length : 0}
          sortable={sortable}
          filterable={filterable}
          onDataStateChange={e => {
            setDataState(e.data);
          }}
        >
          {children}
        </Grid>
      </KendoLocalizationProvider>
      {loading && <LoadingPanel idPortal={`.${className}` ?? '.k-grid'}></LoadingPanel>}
    </>
  );
};

export { CustomGrid };
