import PropTypes from 'prop-types';
import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { useTranslation } from 'react-i18next';
import { MarkDownOutput } from '~/playbook/shared';
import { ReactComponent as CloseIcon } from '~/assets/images/close.svg';
import './conditionsViewer.scss';

export function ConditionsViewer(props) {
  const [t] = useTranslation();

  return (
    <>
      <Modal id="modal" {...props} size="lg" centered>
        <div className="conditionsViewer">
          <div className="conditionsViewer__header">
            <Modal.Title id="title" className="font-weight-bold">
              {props.isPolicy ? t('common.dialogs.modalHeaderPolicy') : t('common.dialogs.modalHeaderConditions')}
            </Modal.Title>
            <CloseIcon className="conditionsViewer__icon" onClick={props.onClose} tabIndex={0} />
          </div>
          <div className="conditionsViewer__content">
            <MarkDownOutput source={props.isPolicy ? t('common.dialogs.policy') : t('common.dialogs.conditions')} />
          </div>
        </div>
      </Modal>
    </>
  );
}

ConditionsViewer.propTypes = {
  isPolicy: PropTypes.bool,
  show: PropTypes.bool,
  onClose: PropTypes.func
};
