import React from 'react';
import { Logos } from '../shared/Logos';
import { useTranslation } from 'react-i18next';
import './home.scss';
import publishers from '~/assets/images/publishers.png';
import researchers from '~/assets/images/researchers.png';
import { Information } from '~/modules/';

const Home = () => {
  const { t } = useTranslation();

  return (
    <>
      <div className="platform-wrapper container">
        <div className="box__content text-center platform-wrapper__header">
          <p className="font-weight-bold">
            <span className="health-text">{t('platform.paragraph1_1')}</span>
            <span className="health-data-29-text">{t('platform.paragraph1_2')}</span>
            {t('platform.paragraph_title')}
          </p>
          <p>{t('platform.paragraph2_1')}</p>
          <p>{t('platform.paragraph2_2')}</p>
          <p>{t('platform.paragraph2_3')}</p>
        </div>
        <div className="box__content text-center">
          <p>
            <span className="health-text">{t('platform.paragraph1_1')}</span>
            <span className="health-data-29-text">{t('platform.paragraph1_2')}</span>
            {t('platform.paragraph1_3')}
          </p>
        </div>
        <h2 className="text-center">{t('platform.howWork_title')}</h2>
        <div className="row">
          <div className="col-md-6">
            <div className="platform-wrapper__img text-center">
              <img alt="logo" src={publishers} />
            </div>
            <div className="platform-wrapper__title">{t('platform.publishers.title')}</div>
            <p className="text-center">
              {t('platform.publishers.paragraph1_1')}
              <a href="/Identity/Account/Register">{t('platform.publishers.paragraph1_2')}</a>
              {t('platform.publishers.paragraph1_3')}
            </p>
          </div>
          <div className="col-md-6">
            <div className="platform-wrapper__img text-center">
              <img alt="logo" src={researchers} />
            </div>
            <div className="platform-wrapper__title">{t('platform.researchers.title')}</div>
            <p className="text-center">
              {t('platform.researchers.paragraph1_1')}
              <a href="/platform/datasets">{t('platform.researchers.paragraph1_2')}</a>
              {t('platform.researchers.paragraph1_3')}
            </p>
          </div>
        </div>
        <div className="box__content text-center">
          <h2>{t('platform.whatDo_title')}</h2>
          <p>
            {t('platform.paragraph3_1')}
            <a href="https://www.foundation29.org" target="_blank" rel="noopener noreferrer">
              {t('platform.paragraph3_2')}
            </a>
            {t('platform.paragraph3_3')}
          </p>
        </div>
        <Information />
        <Logos />
      </div>
    </>
  );
};

export { Home };
