import React from 'react';
import { useTranslation } from 'react-i18next';

const statesClass = {
  0: 'k-messagebox-info',
  1: 'k-messagebox-warning',
  2: 'k-messagebox-success',
  3: 'k-messagebox-error'
};

const StatisticsStateNumberLabel = ({ state, total }) => {
  const { t } = useTranslation();
  return <div className={`k-messagebox ${statesClass[state]}`}>{total}</div>;
};

export { StatisticsStateNumberLabel };
