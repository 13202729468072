import React, { Component } from 'react';
import { Route } from 'react-router';

import { Home } from './components/home/Index';
import Playbook from './playbook/Index';
import { Platform } from './components/platform/Index';
import { Privacy, PrivacyRegister, Terms, TermsRegister, LegalNotice } from './components/privacyTerms/Index';

import ApiAuthorizationRoutes from './components/api-authorization/ApiAuthorizationRoutes';
import { ApplicationPaths } from './components/api-authorization/ApiAuthorizationConstants';
import './shared/utils';

import './styles/app.scss';

export default class App extends Component {
  static displayName = App.name;

  render() {
    return (
      <>
        <Route exact path="/" component={Home} />
        <Route exact path="/playbook" component={Playbook} />
        <Route path="/platform" component={Platform} />
        <Route path="/privacy" component={Privacy}></Route>
        <Route path="/privacy-register" component={PrivacyRegister}></Route>
        <Route path="/terms" component={Terms}></Route>
        <Route path="/terms-register" component={TermsRegister}></Route>
        <Route path="/legal-notice" component={LegalNotice}></Route>
        <Route path={ApplicationPaths.ApiAuthorizationPrefix} component={ApiAuthorizationRoutes} />
      </>
    );
  }
}
