import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LegalNoticeViewer } from './LegalNoticeViewer';
import './legalNotice.scss';

export function LegalNotice() {
  const [t] = useTranslation();
  const [show, setShow] = useState(false);

  const showModal = () => {
    setShow(true);
  };

  const hideModal = () => {
    setShow(false);
  };

  return (
    <>
      <span className="legalNotice__link" onClick={showModal}>
        {t('common.legalNotice')}
      </span>

      <LegalNoticeViewer show={show} onClose={hideModal} />
    </>
  );
}
