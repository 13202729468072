import MarkdownIt from 'markdown-it';
import PropTypes from 'prop-types';
import React from 'react';
import ReactMarkdown from 'react-markdown';

const mdParser = new MarkdownIt({
  html: true,
  linkify: true,
  typographer: true
});

export function MarkDownOutput(props) {
  const { source, wrapperClassName } = props;

  return (
    <ReactMarkdown
      className={`${wrapperClassName}`}
      source={mdParser.render(source)}
      skipHtml={false}
      escapeHtml={false}
    />
  );
}

MarkDownOutput.propTypes = {
  source: PropTypes.string,
  wrapperClassName: PropTypes.string
};
