import React, { Component, useContext } from 'react';
import { Route } from 'react-router';
import { ToastProvider } from 'react-toast-notifications';
import { LayoutPlatformMenu } from '../shared/LayoutPlatformMenu';
import { useTranslation } from 'react-i18next';
import { Home } from './Home';
import { ManageDatasets } from './ManageDatasets';
import { ManageRequests } from './ManageRequests';
import { ManageUsers } from './ManageUsers';
import { PublicDatasets } from './PublicDatasets';
import { PublicDataset } from './PublicDatasets/PublicDataset';
import { RequestDataset } from './PublicDatasets/RequestDataset';
import { RequestViewForm } from './ManageRequests/RequestViewForm';
import { ManageDatasetForm } from './ManageDatasets/ManageDatasetForm';
import AuthorizeRoute from '../api-authorization/AuthorizeRoute';
import { ToastNotification } from '~/components/shared/ToastNotification';
import { ManageLaboratories } from './ManageLaboratories';
import { ManageLaboratoryForm } from './ManageLaboratories/ManageLaboratoryForm';
import { SettingsContext } from '~/contexts/SettingsProvider';

const Platform = () => {
  const { laboratoriesFeatureEnabled } = useContext(SettingsContext);

  return (
    <LayoutPlatformMenu>
      <ToastProvider
        components={{ Toast: ToastNotification }}
        transitionDuration={220}
        autoDismiss={true}
        autoDismissTimeout={6000}
      >
        <Route exact path="/platform" component={Home} />
        <Route exact path="/platform/datasets" component={PublicDatasets} />
        <Route exact path="/platform/datasets/detail/:id" component={PublicDataset} />
        <Route exact path="/platform/datasets/request/:id" component={RequestDataset} />
        <AuthorizeRoute exact path="/platform/manage-datasets/new" component={ManageDatasetForm} />
        <AuthorizeRoute exact path="/platform/manage-datasets/edit/:id" component={ManageDatasetForm} />
        <AuthorizeRoute exact path="/platform/manage-datasets" component={ManageDatasets} />
        <AuthorizeRoute exact path="/platform/manage-requests/:id" component={RequestViewForm} />
        <AuthorizeRoute exact path="/platform/manage-requests" component={ManageRequests} />
        {laboratoriesFeatureEnabled && (
          <AuthorizeRoute exact path="/platform/manage-laboratories" component={ManageLaboratories} />
        )}
        {laboratoriesFeatureEnabled && (
          <AuthorizeRoute exact path="/platform/manage-laboratories/edit/:id" component={ManageLaboratoryForm} />
        )}
        <AuthorizeRoute path="/platform/manage-users" component={ManageUsers} />
      </ToastProvider>
    </LayoutPlatformMenu>
  );
};

export { Platform };
