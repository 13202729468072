import { useEffect, useState } from 'react';
import useAxios from 'axios-hooks';
import authService from '~/components/api-authorization/AuthorizeService';
import { useNotification } from '~/hooks/useNotification';

export const HttpMethod = {
  GET: 'GET',
  POST: 'POST',
  PUT: 'PUT',
  DELETE: 'DELETE'
};

const useAction = ({ httpMethod, messageSuccess }, isAuthenticate = true, notificationSucess = true) => {
  const [dataAction, executeAction] = useState();
  const [notifyError, notifySuccess] = useNotification();

  const [{ data: dataResponse, loading, error, response }, execute] = useAxios(
    {
      method: httpMethod
    },
    { manual: true, useCache: false }
  );

  const executeActionAsync = async () => {
    const token = isAuthenticate ? await authService.getAccessToken() : undefined;
    execute({
      url: dataAction.url,
      data: { ...dataAction.data },
      headers: !token ? {} : { Authorization: `Bearer ${token}` }
    });
  };

  useEffect(() => {
    if (!dataAction) return;
    executeActionAsync();
  }, [dataAction]);

  useEffect(() => {
    if (!response) return;
    if (notificationSucess && response.status >= 200 && response.status < 400) notifySuccess(messageSuccess);
  }, [response]);

  useEffect(() => {
    if (!error) return;
    notifyError('Error: ' + error.message);
  }, [error]);

  return { executeAction, loading, error, dataResponse, response };
};

export { useAction };
