import React from 'react';
import ReactDOM from 'react-dom';
import 'reflect-metadata';
import { BrowserRouter } from 'react-router-dom';
import { SettingsProvider } from './contexts/SettingsProvider';
import App from './App';

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');

fetch('/api/settings')
  .then(res => res.json())
  .then(({ value }) => {
    ReactDOM.render(
      <SettingsProvider settings={value}>
        <BrowserRouter basename={baseUrl}>
          <App />
        </BrowserRouter>
      </SettingsProvider>,
      rootElement
    );
  });
