import React from 'react';
import { useTranslation } from 'react-i18next';
import { DefaultToast } from 'react-toast-notifications';
import { ReactComponent as CheckIcon } from '~/assets/images/check.svg';
import { ReactComponent as CloseIcon } from '~/assets/images/close.svg';
import './notification.scss';

export const Notification = props => {
  const [t] = useTranslation();

  return (
    <div className="notification">
      <DefaultToast {...props}>
        <span className="notification__title">
          {props.appearance === 'error' ? (
            <>
              <CloseIcon className="notification__icon" />
              {t('notification.titles.error')}
            </>
          ) : (
            <>
              <CheckIcon className="notification__icon" />
              {t('notification.titles.success')}
            </>
          )}
        </span>

        <p className="notification__content">{props.children}</p>

        <CloseIcon className="notification__icon notification__icon--close" />
      </DefaultToast>
    </div>
  );
};
