if (!String.format) {
  String.format = function(format) {
    var args = Array.prototype.slice.call(arguments, 1);
    return format.replace(/{(\d+)}/g, function(match, number) {
      return typeof args[number] != 'undefined' ? args[number] : match;
    });
  };
}

if (!Date.toISO) {
  Date.toISO = value => {
    if (value) {
      const date = new Date(Date.UTC(value.getFullYear(), value.getMonth(), value.getDate()));
      return date.toISOString();
    }
    return undefined;
  };
}

const stringToDate = value => {
  if (value) {
    const dateValue = new Date(value);
    if (dateValue instanceof Date && !isNaN(dateValue.getTime())) {
      return dateValue;
    }
  }
  return undefined;
};

if (!String.stringToLocaleString) {
  String.stringToLocaleString = value => {
    const dateValue = stringToDate(value);
    return (
      dateValue &&
      dateValue
        .toISOString()
        .replace(/T/, ' ')
        .replace(/\..+/, '')
    );
  };
}

if (!String.stringToLocaleDateString) {
  String.stringToLocaleDateString = value => {
    const dateValue = stringToDate(value);
    return dateValue && dateValue.toISOString().substring(0, 10);
  };
}
