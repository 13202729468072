import React, { Component, Fragment } from 'react';
import { NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import authService from './AuthorizeService';
import authorizeRole from './AuthorizeRole';
import { ApplicationPaths } from './ApiAuthorizationConstants';
import { Translate } from '../shared/Translate';

export class LoginMenu extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isAuthenticated: false,
      isAdmin: false,
      isPublisher: false
    };
  }

  componentDidMount() {
    this._subscription = authService.subscribe(() => this.populateState());
    this.populateState();
  }

  componentWillUnmount() {
    authService.unsubscribe(this._subscription);
  }

  async populateState() {
    const [isAuthenticated, user, isAdmin, isPublisher] = await Promise.all([
      authService.isAuthenticated(),
      authService.getUser(),
      authorizeRole.isAdminRole(),
      authorizeRole.isPublisherRole()
    ]);
    this.setState({
      isAuthenticated,
      isAdmin,
      isPublisher
    });
  }

  render() {
    const { isAuthenticated, isAdmin, isPublisher } = this.state;
    if (!isAuthenticated) {
      const registerPath = `${ApplicationPaths.Register}`;
      const loginPath = `${ApplicationPaths.Login}`;
      return this.anonymousView(registerPath, loginPath);
    } else {
      const profilePath = `${ApplicationPaths.Profile}`;
      const logoutPath = { pathname: `${ApplicationPaths.LogOut}`, state: { local: true } };
      return this.authenticatedView(isAdmin, isPublisher, profilePath, logoutPath);
    }
  }

  authenticatedView(isAdmin, isPublisher, profilePath, logoutPath) {
    return (
      <Fragment>
        <NavItem>
          <NavLink tag={Link} to={profilePath}>
            {isAdmin ? (
              <Translate i18nKey="platform.menu.admin"></Translate>
            ) : isPublisher ? (
              <Translate i18nKey="platform.menu.publisher"></Translate>
            ) : (
              <Translate i18nKey="platform.menu.user"></Translate>
            )}
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink tag={Link} to={logoutPath}>
            <Translate i18nKey="platform.menu.logout"></Translate>
          </NavLink>
        </NavItem>
      </Fragment>
    );
  }

  anonymousView(registerPath, loginPath) {
    return (
      <Fragment>
        <NavItem>
          <NavLink tag={Link} to={registerPath}>
            <Translate i18nKey="platform.menu.register"></Translate>
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink tag={Link} to={loginPath}>
            <Translate i18nKey="platform.menu.login"></Translate>
          </NavLink>
        </NavItem>
      </Fragment>
    );
  }
}
