import React from 'react';

const PublicDatasetsRow = ({ dataItem }) => {
  const getPublisherName = dataItem => {
    return `${dataItem.name} - ${dataItem.isAnInstitution ? dataItem.institution : 'Individual publisher'}`;
  };

  return (
    <>
      <div className="grid-datasets__title" title={`${dataItem.name} - ${getPublisherName(dataItem)}`}>
        {dataItem.isAnInstitution ? <i className="bi-building"></i> : <i className="bi-file-person"></i>}
        <p>{getPublisherName(dataItem)}</p>
      </div>
      <div className="grid-datasets__description" title={dataItem.description}>
        {dataItem.description}
      </div>
    </>
  );
};

export { PublicDatasetsRow };
