import React from 'react';
import { useTranslation } from 'react-i18next';
import { constants } from '~/shared/constants';
import { LegalNotice } from './LegalNotice';
import { ConditionsNotice } from './ConditionsNotice';
import './footer.scss';
import { Information } from '../../modules';

export function Footer() {
  const [t] = useTranslation();

  return (
    <footer className="footer main-container">
      <p className="footer__info">
        {t('footer.contact')}
        <a href={`mailto:${constants.foundationMailInfo}?Subject=HealtData%2029%20Información`}>
          {constants.foundationMailInfo}
        </a>
      </p>
      <p>
        <a className="footer__link" href={constants.foundationUrl}>
          {t('footer.about')}
        </a>
        {' - '}
        <LegalNotice />
        {' - '}
        <ConditionsNotice />
      </p>
    </footer>
  );
}
