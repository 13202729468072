import React from 'react';

import { DropDownList } from '@progress/kendo-react-dropdowns';

export default function StateFilterCell(data, defaultItem, textField, valueField) {
  return class extends React.Component {
    static displayName = StateFilterCell.name;
    render() {
      const valueFilter = data.find(value => value.id === this.props.value);
      return (
        <div className="k-filtercell">
          <DropDownList
            data={data}
            onChange={this.onChange}
            defaultItem={defaultItem}
            textField={textField}
            valueField={valueField}
            value={valueFilter || defaultItem}
          />
          <button
            className="k-button k-button-icon k-clear-button-visible"
            title="Clear"
            disabled={!this.hasValue(this.props.value)}
            onClick={this.onClearButtonClick}
          >
            <span className={this.hasValue(this.props.value) ? 'k-icon k-i-filter-clear' : 'k-icon k-i-filter'} />
          </button>
        </div>
      );
    }

    hasValue = value => Boolean(value && value !== defaultItem[valueField]);

    onChange = event => {
      const hasValue = this.hasValue(event.target.value[valueField]);
      this.props.onChange({
        value: hasValue ? event.target.value[valueField] : '',
        operator: hasValue ? 'eq' : '',
        syntheticEvent: event.syntheticEvent
      });
    };

    onClearButtonClick = event => {
      event.preventDefault();
      this.props.onChange({
        value: '',
        operator: '',
        syntheticEvent: event
      });
    };
  };
}
