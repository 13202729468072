import authService from './AuthorizeService';

export class AuthorizeRole {
  async isAdminRole() {
    return await this.isInRole(PlatformRoles.Admin);
  }

  async isPublisherRole() {
    return await this.isInRole(PlatformRoles.Publisher);
  }

  async isInRole(role) {
    const roles = await this.getUserRoles();
    if (roles) {
      return roles.includes(role);
    }
    return false;
  }

  async getUserRoles() {
    const token = await authService.getAccessToken();
    if (token) {
      const profile = this.parseJwt(token);
      if (profile && profile.role) {
        if (Array.isArray(profile.role)) return profile.role;
        else return [profile.role];
      }
    }
  }

  parseJwt(token) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map(function(c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join('')
    );

    return JSON.parse(jsonPayload);
  }
}

const authorizeRole = new AuthorizeRole();

export default authorizeRole;

export const PlatformRoles = {
  Admin: 'PlatformAdmin',
  Publisher: 'Publisher'
};
