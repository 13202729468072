import React, { useEffect, useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Form, FormElement } from '@progress/kendo-react-form';
import { useAction, HttpMethod } from '~/hooks/useAction';
import { urlBuilder } from '~/shared/constants';
import { getCurrentLanguageLocale } from '~/services/app.i18n';
import { LoadingPanel } from '~/components/shared/LoadingPanel';
import { KendoLocalizationProvider } from '~/components/shared/KendoLocalizationProvider';
import { FormApplicantElement } from './FormApplicantElement';
import { FormRepresentativeElement } from './FormRepresentativeElement';
import { FormAuthorizedElement } from './FormAuthorizedElement';
import { FormStudentElement } from './FormStudentElement';
import { FormProjectElement } from './FormProjectElement';
import { FormWantLaboratory } from './FormWantLaboratory';
import { RequestDatasetResponseMessage } from './RequestDatasetResponseMessage';
import { FormAcceptTermsElement } from './FormAcceptTermsElement';
import { SettingsContext } from '~/contexts/SettingsProvider';

const RequestDataset = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const history = useHistory();

  const { laboratoriesFeatureEnabled } = useContext(SettingsContext);

  const { executeAction: executeGet, loading, response } = useAction(
    {
      httpMethod: HttpMethod.GET
    },
    false,
    false
  );

  useEffect(() => {
    const onGetDataset = () => {
      executeGet({ url: urlBuilder.datasets.getPublicDataset(id, getCurrentLanguageLocale()) });
    };
    onGetDataset();
  }, [executeGet, id, t]);

  const [responseRequest, setResponseRequest] = useState(0);
  const [acceptState, setAcceptState] = useState(false);

  const { executeAction: executeAddNew, loading: loadingAddNew, response: responseAddNew } = useAction(
    {
      httpMethod: HttpMethod.POST
    },
    false,
    true
  );

  const { executeAction: executeAddMailing } = useAction(
    {
      httpMethod: HttpMethod.POST
    },
    false,
    false
  );

  const handleSubmit = dataItem => {
    const requestDataItem = {
      ...dataItem,
      applicant: {
        ...dataItem.applicant,
        dateOfIssue: Date.toISO(dataItem.applicant.dateOfIssue),
        dueDate: Date.toISO(dataItem.applicant.dueDate)
      },
      representative: dataItem.representative
        ? {
            ...dataItem.representative,
            dateOfIssue: Date.toISO(dataItem.representative.dateOfIssue),
            dueDate: Date.toISO(dataItem.representative.dueDate)
          }
        : undefined,
      authorized: dataItem.authorized
        ? {
            ...dataItem.authorized,
            dateOfIssue: Date.toISO(dataItem.authorized.dateOfIssue),
            dueDate: Date.toISO(dataItem.authorized.dueDate)
          }
        : undefined,
      student: dataItem.student
        ? {
            ...dataItem.student,
            dateOfIssue: Date.toISO(dataItem.student.dateOfIssue),
            dueDate: Date.toISO(dataItem.student.dueDate)
          }
        : undefined,
      language: getCurrentLanguageLocale(),
      wantLaboratory: laboratoriesFeatureEnabled ? dataItem.wantLaboratory : false
    };
    if (dataItem.ReceiveCommercial) sendFlow(dataItem);
    executeAddNew({ url: urlBuilder.datasets.postPublicRequestDataset(id), data: requestDataItem });
  };

  function sendFlow(dataItem) {
    var d = new Date(Date.now());
    var a = getFormattedDate(d);
    var objectSend = {
      Email: dataItem.applicant.institutionalEmail,
      Name: dataItem.applicant.name,
      Lang: getCurrentLanguageLocale(),
      Option: 'Request',
      Date: a
    };
    var urlToSend =
      'https://prod-195.westeurope.logic.azure.com:443/workflows/d33233ca5e5c40c19d33df2b11b6ba8c/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=-LvyKNmdEh_6CL6aYXqapDlW-3NuDlRd8W86PREJ3QI';
    executeAddMailing({ url: urlToSend, data: objectSend });
  }

  function getFormattedDate(date) {
    let year = date.getFullYear();
    let month = (1 + date.getMonth()).toString().padStart(2, '0');
    let day = date
      .getDate()
      .toString()
      .padStart(2, '0');
    return month + '/' + day + '/' + year;
  }

  useEffect(() => {
    if (responseAddNew && responseAddNew.data) setResponseRequest(responseAddNew.data);
  }, [responseAddNew]);

  const typesID = [
    { id: 1, name: t('platform.requestDataset.typesID.passport') },
    { id: 2, name: t('platform.requestDataset.typesID.drivingLicence') },
    { id: 3, name: t('platform.requestDataset.typesID.dni') },
    { id: 4, name: t('platform.requestDataset.typesID.nSegSocial') },
    { id: 5, name: t('platform.requestDataset.typesID.other') }
  ];

  const getPublisherName = dataItem => {
    return `${dataItem.name} - ${dataItem.isAnInstitution ? dataItem.institution : 'Individual publisher'}`;
  };

  return responseRequest > 0 ? (
    <RequestDatasetResponseMessage idRequest={responseRequest}></RequestDatasetResponseMessage>
  ) : (
    <KendoLocalizationProvider>
      <div className="row justify-content-md-center">
        <div className="col-md-8">
          <h1 className="health-data-title__big loading-container">{t('platform.requestDataset.title')}</h1>
          <p className="health-data-subtitle">{t('platform.requestDataset.applyFor')}</p>
          {response && response.data && (
            <p className="health-data-subtitle data-set-name">{getPublisherName(response.data)}</p>
          )}
          <p className="health-data-subtitle">{t('platform.requestDataset.subtitle')}</p>
          <p className="health-data-subtitle text-red">{t('platform.requestDataset.requiredFields')}</p>
          <Form
            className="k-form"
            onSubmit={handleSubmit}
            render={formRenderProps => {
              return (
                <FormElement>
                  <FormApplicantElement typesID={typesID} />
                  <FormRepresentativeElement typesID={typesID} />
                  <FormAuthorizedElement typesID={typesID} />
                  <FormStudentElement typesID={typesID} />
                  <FormProjectElement />
                  {laboratoriesFeatureEnabled && <FormWantLaboratory dataItem={response?.data} />}
                  <FormAcceptTermsElement dataItem={response?.data} formRenderProps={formRenderProps} />
                  {acceptState && formRenderProps.visited && !formRenderProps.valid && (
                    <div className="k-form-field">
                      <div className={'k-messagebox k-messagebox-error'}>{t('validators.formInvalid')}</div>
                    </div>
                  )}
                  <div className="k-form-buttons">
                    <button
                      type={'reset'}
                      className="health-data-button"
                      onClick={() => {
                        history.replace('/platform/datasets/detail/' + response.data.id);
                      }}
                    >
                      {t('platform.requestDataset.cancel')}
                    </button>
                    <button
                      type={'submit'}
                      className="health-data-button"
                      onClick={() => {
                        setAcceptState(true);
                      }}
                    >
                      {t('platform.requestDataset.submit')}
                    </button>
                  </div>
                </FormElement>
              );
            }}
          />
        </div>
      </div>
      {(loading || loadingAddNew) && <LoadingPanel idPortal=".k-form" isFixed></LoadingPanel>}
    </KendoLocalizationProvider>
  );
};

export { RequestDataset };
