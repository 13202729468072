import React from 'react';
import { Error, Hint } from '@progress/kendo-react-labels';
import { Input, Checkbox } from '@progress/kendo-react-inputs';
import { DatePicker } from '@progress/kendo-react-dateinputs';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { useTranslation } from 'react-i18next';

const requiredSymbol = ' *';

export const FormInput = fieldRenderProps => {
  const { t } = useTranslation();

  const { validationMessage, visited, label, maxLength, showRequiredSymbol, hintMessage, ...others } = fieldRenderProps;
  return (
    <div>
      <Input
        label={`${label}${showRequiredSymbol ? requiredSymbol : ''}`}
        validationMessage={String.format(t(validationMessage), label)}
        maxLength={maxLength ?? 255}
        {...others}
      />
      {hintMessage && <Hint>{hintMessage}</Hint>}
      <Error>{visited && validationMessage && String.format(t(validationMessage), label)}</Error>
    </div>
  );
};

export const FormTextArea = fieldRenderProps => {
  const { t } = useTranslation();

  const {
    validationMessage,
    visited,
    label,
    valid,
    modified,
    touched,
    showRequiredSymbol,
    ...others
  } = fieldRenderProps;
  return (
    <div className="k-textbox-container">
      <label className="custom-label">{`${label}${showRequiredSymbol ? requiredSymbol : ''}`}</label>
      <textarea className={'k-textarea k-autofill'} {...others} />
      <Error>{visited && validationMessage && String.format(t(validationMessage), label)}</Error>
    </div>
  );
};

export const FormDatePicker = fieldRenderProps => {
  const { t } = useTranslation();

  const { validationMessage, visited, label, showRequiredSymbol, ...others } = fieldRenderProps;
  return (
    <>
      <label className="custom-label">{`${label}${showRequiredSymbol ? requiredSymbol : ''}`}</label>
      <DatePicker format="dd/MMM/yyyy" validationMessage={String.format(t(validationMessage), label)} {...others} />
      <Error>{visited && validationMessage && String.format(t(validationMessage), label)}</Error>
    </>
  );
};

export const FormDropDownList = fieldRenderProps => {
  const { t } = useTranslation();

  const { validationMessage, visited, label, showRequiredSymbol, ...others } = fieldRenderProps;
  const editorRef = React.useRef(null);

  return (
    <>
      <DropDownList
        label={`${label}${showRequiredSymbol ? requiredSymbol : ''}`}
        ref={editorRef}
        validationMessage={String.format(t(validationMessage), label)}
        {...others}
      />
      <Error>{visited && validationMessage && String.format(t(validationMessage), label)}</Error>
    </>
  );
};

export const FormCheckbox = fieldRenderProps => {
  const { t } = useTranslation();

  const { validationMessage, visited, label, touched, modified, showRequiredSymbol, ...others } = fieldRenderProps;
  return (
    <div>
      <Checkbox
        label={`${label}${showRequiredSymbol ? requiredSymbol : ''}`}
        validationMessage={String.format(t(validationMessage), label)}
        {...others}
      />
      <Error>{visited && validationMessage && String.format(t(validationMessage), label)}</Error>
    </div>
  );
};

export const FormCheckboxTerms = fieldRenderProps => {
  const { t } = useTranslation();

  const { validationMessage, visited, label, touched, modified, labelComponent, ...others } = fieldRenderProps;
  return (
    <div>
      <Checkbox label={labelComponent} validationMessage={String.format(t(validationMessage), label)} {...others} />
      <Error>{visited && validationMessage && String.format(t(validationMessage), label)}</Error>
    </div>
  );
};

export const FormError = fieldArrayRenderProps => {
  const { validationMessage, visited, label } = fieldArrayRenderProps;
  return <Error>{visited && validationMessage && String.format(validationMessage, label)}</Error>;
};
