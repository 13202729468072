import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { GridColumn } from '@progress/kendo-react-grid';
import { urlBuilder } from '~/shared/constants';
import { CustomGrid } from '../shared/CustomGrid';
import { useFetch } from '~/hooks/useFetch';
import { UserActionsDialogConfirm, UserActions } from './ManageUsers/UserActionsDialogConfirm';
import { UserContactInfoCard } from './ManageUsers/UserContactInfoCard';
import './manageUsers.scss';

const ManageUsers = () => {
  const { t } = useTranslation();
  const { reload, loading, data } = useFetch(urlBuilder.users.getUsers, true);

  const [userInConfirm, setUserInConfirm] = useState();
  const [userInCard, setUserInCard] = useState();

  const closeDialogConfirm = reloadData => {
    setUserInConfirm(undefined);
    if (reloadData) reload();
  };

  const closeUserCard = () => {
    setUserInCard(undefined);
  };

  const openDialogConfirm = (user, action) => {
    setUserInConfirm({ user: Object.assign({}, user), action });
  };

  return (
    <>
      <h1>{t('platform.users.title')}</h1>
      <CustomGrid loading={loading} data={data}>
        <GridColumn
          field="name"
          title={t('platform.users.columns.name')}
          cell={props => (
            <td>
              <span title={props.dataItem.name}>{props.dataItem.name}</span>
            </td>
          )}
        ></GridColumn>
        <GridColumn
          field="surname"
          title={t('platform.users.columns.surname')}
          cell={props => (
            <td>
              <span title={props.dataItem.surname}>{props.dataItem.surname}</span>
            </td>
          )}
        ></GridColumn>
        <GridColumn
          field="position"
          title={t('platform.users.columns.position')}
          cell={props => (
            <td>
              <span title={props.dataItem.position}>{props.dataItem.position}</span>
            </td>
          )}
        ></GridColumn>
        <GridColumn
          field="institutionRegister"
          title={t('platform.users.columns.institution')}
          cell={props => (
            <td>
              <span
                title={
                  props.dataItem.emailConfirmed || props.dataItem.isDisabled
                    ? props.dataItem.institution
                    : props.dataItem.institutionRegister
                }
              >
                {props.dataItem.emailConfirmed || props.dataItem.isDisabled
                  ? props.dataItem.institution
                  : props.dataItem.institutionRegister}
              </span>
            </td>
          )}
        ></GridColumn>
        <GridColumn
          field="email"
          title={t('platform.users.columns.contactInfo')}
          cell={props => (
            <td className="text-center disabled-ellipsis">
              <button
                className={'health-data-button health-data-button__action'}
                onClick={() => {
                  setUserInCard(props.dataItem);
                }}
              >
                {t('platform.users.viewCard')}
              </button>
            </td>
          )}
        ></GridColumn>
        <GridColumn
          title={t('platform.users.columns.state')}
          field="emailConfirmed"
          filterable={false}
          cell={props => (
            <td className="text-center disabled-ellipsis grid-icon-buttons">
              {!props.dataItem.emailConfirmed ? (
                props.dataItem.isDisabled ? (
                  <button
                    className="k-button k-button-new k-button-icon"
                    title={t('platform.users.enable')}
                    onClick={() => {
                      openDialogConfirm(
                        props.dataItem,
                        props.dataItem.institutionRegister === undefined || props.dataItem.institutionRegister === null
                          ? UserActions.EnableWithoutInstitution
                          : UserActions.Enable
                      );
                    }}
                  >
                    <span className={'k-icon k-i-check-outline'} />
                  </button>
                ) : (
                  <button
                    className="k-button k-button-accept k-button-icon"
                    title={t('platform.users.confirm')}
                    onClick={() => {
                      openDialogConfirm(
                        props.dataItem,
                        props.dataItem.institutionRegister === undefined || props.dataItem.institutionRegister === null
                          ? UserActions.ConfirmWithoutInstitution
                          : UserActions.Confirm
                      );
                    }}
                  >
                    <span className={'k-icon k-i-check-outline'} />
                  </button>
                )
              ) : (
                <button
                  className="k-button k-button-reject k-button-icon"
                  title={t('platform.users.disable')}
                  onClick={() => {
                    openDialogConfirm(
                      props.dataItem,
                      props.dataItem.institutionRegister === undefined || props.dataItem.institutionRegister === null
                        ? UserActions.DisableWithoutInstitution
                        : UserActions.Disable
                    );
                  }}
                >
                  <span className={'k-icon k-i-close-outline'} />
                </button>
              )}
            </td>
          )}
        ></GridColumn>
      </CustomGrid>
      {userInConfirm && (
        <UserActionsDialogConfirm actionData={userInConfirm} close={closeDialogConfirm}></UserActionsDialogConfirm>
      )}
      {userInCard && <UserContactInfoCard userInfo={userInCard} close={closeUserCard}></UserContactInfoCard>}
    </>
  );
};

export { ManageUsers };
