export const requiredValidator = value => (value ? '' : 'validators.required');

export const deleteDatasetMessage = (nameDataset, translation) => {
  return String.format(translation('platform.manage.deleteDatasetMessage'), nameDataset);
};

export const confirmRegisterUserMessage = (nameUser, institution, translation) => {
  return String.format(translation('platform.users.confirmRegisterUserMessage'), nameUser, institution);
};

export const confirmRegisterUserMessageWithoutInstitution = (nameUser, translation) => {
  return String.format(translation('platform.users.confirmRegisterUserMessageWithoutInstitution'), nameUser);
};

export const disableUserMessage = (nameUser, institution, translation) => {
  return String.format(translation('platform.users.disableUserMessage'), nameUser, institution);
};

export const disableUserMessageWithoutInstitution = (nameUser, translation) => {
  return String.format(translation('platform.users.disableUserMessageWithoutInstitution'), nameUser);
};

export const enableUserMessage = (nameUser, institution, translation) => {
  return String.format(translation('platform.users.enableUserMessage'), nameUser, institution);
};

export const enableUserMessageWithoutInstitution = (nameUser, translation) => {
  return String.format(translation('platform.users.enableUserMessageWithoutInstitution'), nameUser);
};

export const requiredValidatorMessage = (nameField, translation) => {
  return String.format(translation('validators.required'), nameField);
};

const emailRegex = new RegExp(/\S+@\S+\.\S+/);
export const emailValidator = value => (!value || emailRegex.test(value) ? '' : 'validators.email');
export const emailValidatorRequired = value => (emailRegex.test(value) ? '' : 'validators.email');

const urlRegex = new RegExp(
  /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/
);
export const urlValidator = value => (!value || urlRegex.test(value) ? '' : 'validators.url');
export const urlValidatorRequired = value => (urlRegex.test(value) ? '' : 'validators.url');

export const maxLength1000Validator = value => (!value || value.length <= 1000 ? '' : 'validators.maxLength1000');

export const maxLength250Validator = value => (!value || value.length <= 250 ? '' : 'validators.maxLength250');

export const acceptTermsValidator = value =>
  !value ? 'platform.requestDataset.fields.acceptTermsValidationMessage' : '';
export const acceptPrivacyValidator = value =>
  !value ? 'platform.requestDataset.fields.acceptPrivacyValidationMessage' : '';
export const acceptPrivacyF29Validator = value =>
  !value ? 'platform.requestDataset.fields.acceptPrivacyF29ValidationMessage' : '';
