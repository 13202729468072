import React from 'react';
import { useTranslation } from 'react-i18next';
import { Field } from '@progress/kendo-react-form';
import { FormInput, FormDatePicker, FormDropDownList } from '~/components/shared/form-fields';
import { requiredValidator, emailValidatorRequired } from '~/components/shared/form-validators';

const FormApplicantElement = ({ typesID }) => {
  const { t } = useTranslation();

  return (
    <>
      <p className="health-data-subtitle">
        <b>{t('platform.requestDataset.titleApplicant')}</b>
        <br></br>
        <span className="health-data-subtitle description">{t('platform.requestDataset.descriptionApplicant')}</span>
      </p>
      <div className="mb-3">
        <Field
          name={'applicant.name'}
          component={FormInput}
          label={t('platform.requestDataset.fields.applicant.name')}
          validator={requiredValidator}
          showRequiredSymbol
        />
      </div>
      <div className="mb-3">
        <Field
          name={'applicant.title'}
          component={FormInput}
          label={t('platform.requestDataset.fields.applicant.title')}
          validator={requiredValidator}
          showRequiredSymbol
        />
      </div>
      <div className="mb-3">
        <Field
          name={'applicant.position'}
          component={FormInput}
          label={t('platform.requestDataset.fields.applicant.position')}
          validator={requiredValidator}
          showRequiredSymbol
        />
      </div>
      <div className="mb-3">
        <Field
          name={'applicant.affiliation'}
          component={FormInput}
          label={t('platform.requestDataset.fields.applicant.affiliation')}
          validator={requiredValidator}
          showRequiredSymbol
        />
      </div>
      <div className="mb-3">
        <Field
          name={'applicant.institutionalEmail'}
          component={FormInput}
          label={t('platform.requestDataset.fields.applicant.institutionalEmail')}
          validator={emailValidatorRequired}
          showRequiredSymbol
        />
      </div>
      <div className="mb-3">
        <Field
          name={'applicant.address'}
          component={FormInput}
          label={t('platform.requestDataset.fields.applicant.address')}
          validator={requiredValidator}
          showRequiredSymbol
        />
      </div>
      <fieldset className={'k-form-fieldset'}>
        <legend className={'k-form-legend'}>
          {t('platform.requestDataset.fields.applicant.identificationDocumentLegend')}
        </legend>
        <div className="mb-3">
          <Field
            key={'applicant.identificationDocument'}
            id={'applicant.identificationDocument'}
            name={'applicant.identificationDocument'}
            label={t('platform.requestDataset.fields.applicant.identificationDocument')}
            component={FormDropDownList}
            data={typesID}
            textField={'name'}
            valueField={'id'}
            validator={requiredValidator}
            showRequiredSymbol
          />
        </div>
        <div className="mb-3">
          <Field
            name={'applicant.identificationNumber'}
            component={FormInput}
            label={t('platform.requestDataset.fields.applicant.identificationNumber')}
            validator={requiredValidator}
            showRequiredSymbol
          />
        </div>
        <div className="mb-3">
          <Field
            name={'applicant.countryID'}
            component={FormInput}
            label={t('platform.requestDataset.fields.applicant.countryID')}
            validator={requiredValidator}
            showRequiredSymbol
          />
        </div>
        <div className="mb-3">
          <Field
            name={'applicant.dateOfIssue'}
            component={FormDatePicker}
            label={t('platform.requestDataset.fields.applicant.dateOfIssue')}
            validator={requiredValidator}
            showRequiredSymbol
          />
        </div>
        <div className="mb-3">
          <Field
            name={'applicant.dueDate'}
            component={FormDatePicker}
            label={t('platform.requestDataset.fields.applicant.dueDate')}
            validator={requiredValidator}
            showRequiredSymbol
          />
        </div>
      </fieldset>
    </>
  );
};

export { FormApplicantElement };
