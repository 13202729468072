import React, { useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useAction, HttpMethod } from '~/hooks/useAction';
import { CustomGrid } from '../shared/CustomGrid';
import { urlBuilder } from '~/shared/constants';
import { GridColumn } from '@progress/kendo-react-grid';
import { getCurrentLanguageLocale } from '~/services/app.i18n';

import './manageLaboratories.scss';

const ManageLaboratories = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const { executeAction: executeSearch, loading: loadingGrid, response: dataGrid } = useAction(
    {
      httpMethod: HttpMethod.GET
    },
    true,
    false
  );

  const onSearch = useCallback(
    () => executeSearch({ url: urlBuilder.laboratories.getLaboratories(getCurrentLanguageLocale()) }),
    [executeSearch]
  );

  useEffect(() => {
    onSearch();
  }, [onSearch]);

  const edit = dataItem => {
    history.push('/platform/manage-laboratories/edit/' + dataItem.id);
  };

  return (
    <>
      <h1>{t('platform.laboratories.title')}</h1>
      <p>{t('platform.laboratories.subtitle')}</p>
      <CustomGrid
        loading={loadingGrid}
        data={dataGrid && dataGrid.data}
        className={'grid-manage-laboratories'}
        onRowClick={e => {
          edit(e.dataItem);
        }}
      >
        <GridColumn
          field="institution"
          title={t('platform.laboratories.columns.institution')}
          cell={props => (
            <td className={'cell-selectable'} onClick={() => edit(props.dataItem)}>
              <span title={props.dataItem.institution}>{props.dataItem.institution}</span>
            </td>
          )}
        ></GridColumn>
        <GridColumn
          field="publisherName"
          title={t('platform.laboratories.columns.publisherName')}
          cell={props => (
            <td className={'cell-selectable'} onClick={() => edit(props.dataItem)}>
              <span title={props.dataItem.publisherName}>{props.dataItem.publisherName}</span>
            </td>
          )}
        ></GridColumn>
        <GridColumn
          field="publisherSurname"
          title={t('platform.laboratories.columns.publisherSurname')}
          cell={props => (
            <td className={'cell-selectable'} onClick={() => edit(props.dataItem)}>
              <span title={props.dataItem.publisherSurname}>{props.dataItem.publisherSurname}</span>
            </td>
          )}
        ></GridColumn>
        <GridColumn
          field="name"
          title={t('platform.laboratories.columns.name')}
          cell={props => (
            <td className={'cell-selectable'} onClick={() => edit(props.dataItem)}>
              <span title={props.dataItem.name}>{props.dataItem.name}</span>
            </td>
          )}
        ></GridColumn>
        <GridColumn
          sortable={false}
          filterable={false}
          title={t('platform.laboratories.columns.state')}
          cell={props => (
            <td className={'cell-selectable'} onClick={() => edit(props.dataItem)}>
              {props.dataItem.canRequestLaboratory && (
                <div className="cell-container-buttons grid-icon-buttons">
                  <div className="check-icon k-button-accept k-button-icon">
                    <span className={'k-icon k-i-check-outline'} />
                  </div>
                </div>
              )}
            </td>
          )}
        ></GridColumn>
      </CustomGrid>
    </>
  );
};

export { ManageLaboratories };
