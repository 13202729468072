export const constants = {
  foundationUrl: 'https://www.foundation29.org',
  foundationMailInfo: 'healthdata@foundation29.org',
  microsoftUrl: 'https://www.microsoft.com/',
  garriguesUrl: 'https://www.garrigues.com/',
  uvUrl: 'https://www.uv.es/catedra-microsoft/es/catedra-privacidad-transformacion-digital-microsoft-uv.html'
};

export const urlBuilder = {
  datasets: {
    getPublicDatasets: (searchText, language, individual, institution) =>
      `/api/datasets?api-version=1.0${searchText.length > 0 ? `&searchText=${searchText}` : ''}${
        language.length > 0 ? `&language=${language}` : ''
      }&individual=${individual}&institution=${institution} `,
    getPublicDataset: (id, language) =>
      `/api/datasets/${id}?api-version=1.0${language.length > 0 ? `&language=${language}` : ''}`,
    postPublicRequestDataset: id => `/api/datasets/${id}/request?api-version=1.0`,
    getAdminDatasets: (searchText, language) =>
      `/api/admin/datasets?api-version=1.0${searchText.length > 0 ? `&searchText=${searchText}` : ''}${
        language?.length > 0 ? `&language=${language}` : ''
      }`,
    getAdminDataset: id => `/api/admin/datasets/${id}?api-version=1.0`,
    postAdminDatasets: '/api/admin/datasets?api-version=1.0',
    putAdminDatasets: '/api/admin/datasets?api-version=1.0',
    deleteAdminDatasets: id => `/api/admin/datasets/${id}?api-version=1.0`
  },
  petitions: {
    getAccessPetitions: (searchText, language) =>
      `/api/admin/petitions?api-version=1.0${searchText.length > 0 ? `&searchText=${searchText}` : ''}${
        language.length > 0 ? `&language=${language}` : ''
      }`,
    getAccessPetition: (id, language) =>
      `/api/admin/petitions/${id}?api-version=1.0${language.length > 0 ? `&language=${language}` : ''}`,
    putAccessPetition: id => `/api/admin/petitions/${id}?api-version=1.0`
  },
  users: {
    getUsers: '/api/admin/users',
    registerConfirmationUser: id => `/api/admin/users/${id}/confirm`,
    disableUser: id => `/api/admin/users/${id}/disable`,
    me: '/api/users/me?api-version=1.0'
  },
  laboratories: {
    getLaboratories: language =>
      `/api/admin/laboratories?api-version=1.0${language?.length > 0 ? `&language=${language}` : ''}`,
    getLaboratory: id => `/api/admin/laboratories/${id}?api-version=1.0`,
    putLaboratory: id => `/api/admin/laboratories/${id}?api-version=1.0`
  },
  languages: {
    getLanguages: 'api/languages?api-version=1.0'
  },
  files: {
    savePublisherTempFile: '/api/admin/files/savePublisherTempFile?api-version=1.0',
    deletePublisherTempFile: '/api/admin/files/deletePublisherTempFile?api-version=1.0'
  }
};
