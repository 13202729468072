import React from 'react';
import { useTranslation } from 'react-i18next';
import { RequestViewFormField } from './RequestViewFormField';
import { RequestViewFormFieldArea } from './RequestViewFormFieldArea';

const RequestViewProjectData = ({ dataItem }) => {
  const { t } = useTranslation();
  return (
    <>
      {dataItem && (
        <fieldset className={'k-form-fieldset'}>
          <legend className={'k-form-legend'}>{t('platform.petition.project')}</legend>
          <RequestViewFormField label={t('platform.petition.fields.projectTitle')} value={dataItem.projectTitle} />
          <RequestViewFormField label={t('platform.petition.fields.projectWeb')} value={dataItem.projectWeb} />
          <RequestViewFormFieldArea
            label={t('platform.petition.fields.researchTrack')}
            value={dataItem.researchTrack}
            rows={4}
          />
          <RequestViewFormFieldArea
            label={t('platform.petition.fields.researchProject')}
            value={dataItem.researchProject}
            rows={6}
          />
        </fieldset>
      )}
    </>
  );
};

export { RequestViewProjectData };
