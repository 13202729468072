import PropTypes from 'prop-types';
import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CloseIcon } from '~/assets/images/close.svg';
import { MarkDownOutput } from '~/playbook/shared';
import './legalNoticeViewer.scss';

export function LegalNoticeViewer(props) {
  const [t] = useTranslation();

  return (
    <>
      <Modal id="modal" {...props} size="lg" centered>
        <div className="legalNoticeViewer">
          <div className="legalNoticeViewer__header">
            <Modal.Title id="title">
              <h1>{t('common.dialogs.modalHeaderLegalNotice')}</h1>
            </Modal.Title>
            <CloseIcon className="legalNoticeViewer__icon" onClick={props.onClose} tabIndex={0} />
          </div>
          <div className="legalNoticeViewer__content">
            <MarkDownOutput source={t('common.dialogs.legalNotice')} />
          </div>
        </div>
      </Modal>
    </>
  );
}

LegalNoticeViewer.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.func
};
