import React from 'react';
import { useTranslation } from 'react-i18next';
import { Field } from '@progress/kendo-react-form';
import { FormCheckbox } from '~/components/shared/form-fields';

const FormWantLaboratory = ({ dataItem }) => {
  const { t } = useTranslation();

  return (
    <>
      {dataItem?.canRequestLaboratory && (
        <>
          <p className="health-data-subtitle">
            <b>{t('platform.requestDataset.titleWantLaboratory')}</b>
            <br></br>
            <span className="health-data-subtitle description">
              {t('platform.requestDataset.descriptionWantLaboratory')}
            </span>
          </p>
          <div className="mb-3">
            <Field
              name={'wantLaboratory'}
              component={FormCheckbox}
              label={t('platform.requestDataset.fields.wantLaboratory')}
            />
          </div>
        </>
      )}
    </>
  );
};

export { FormWantLaboratory };
