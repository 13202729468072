import React from 'react';
import { useTranslation } from 'react-i18next';
import { getCurrentLanguageCode, getLanguageList } from '~/services/app.i18n';
import { MarkDownOutput, Section } from '~/playbook/shared';
import openHealth from '~/assets/images/header_openhealth.jpg';
import { Guide, Information, Playbook } from '../';
import './home.scss';

export function Home() {
  const [t] = useTranslation();

  return (
    <main className="home main-container text-center">
      <section className="box-wrapper">
        <img className="box__image" alt="logo" src={openHealth} id="logoId" />
        <h1 className="box__title box__title--light">{t('home.slogan')}</h1>
        <div className="box__content">
          <p>
            {t('home.paragrah2_1_1')}
            <span className="font-weight-bold">
              <a href="https://www.foundation29.org" target="_blank" rel="noopener noreferrer">
                {t('home.paragrah2_1_2')}
              </a>
            </span>
            {t('home.paragrah2_1_3')}
            <span className="health-text">{t('home.paragrah2_2_1')}</span>
            <span className="health-data-29-text">{t('home.paragrah2_2_2')}</span>
            <span>{t('home.paragrah2_3_1')}</span>
            <span className="font-weight-bold">
              <a href="/platform">{t('home.paragrah2_3_2')}</a>
            </span>
            <span>{t('home.paragrah2_3_3')}</span>
          </p>
        </div>
      </section>
      <Section title={t('home.playbookTitle')} id="playbook">
        <Playbook languages={getLanguageList()} />
      </Section>
      <Section title={t('common.whatIsIt')} id="whatIsIt">
        <div className="box__content">
          <p>{t('home.whatIsItText')}</p>
        </div>
      </Section>
      <Section title={t('common.forWhom')} id="forWhom">
        <div className="box__content">
          <p>{t('home.forWhomText')}</p>
        </div>
      </Section>
      <Section title={t('home.dowloadGuide')} id="download">
        <Guide languages={getLanguageList()} />
      </Section>
      <Section title={t('home.whatDo')} id="whatDo">
        <div className="box__content">
          <p>
            {t('home.whatDo_paragraph1_1')}
            <span className="font-weight-bold">
              <a href="https://www.foundation29.org" target="_blank" rel="noopener noreferrer">
                {t('home.whatDo_paragraph1_2')}
              </a>
            </span>
            {t('home.whatDo_paragraph1_3')}
          </p>
        </div>
      </Section>
      <Information />
    </main>
  );
}
