import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DownloadLink, PdfViewer } from '~/playbook/shared';
import logo from '~/assets/images/healthdata_playbook.jpg';
import './guide.scss';

export function Guide(props) {
  const [t] = useTranslation();
  const [showGuide, setShowGuide] = useState(false);
  const [showDownloadDropDown, setShowDownloadDropDown] = useState(false);
  const [showReadOnlineDropDown, setShowReadOnlineDropDown] = useState(false);
  const [readOnlineLanguageSelected, setReadonlineLanguageSelected] = useState(null);

  const getDownloadUrl = language => {
    return `/api/file/download?language=${language}`;
  };

  const hideGuide = () => {
    setShowGuide(false);
  };

  const getDocumentUrl = language => {
    return `/api/file?language=${language}`;
  };

  const renderDownloadDropDown = () => {
    if (!showDownloadDropDown) return null;

    if (props.languages === undefined && props.languages.length === 0) {
      return (
        <li className="download-item">
          <span>No languages download available</span>
        </li>
      );
    }

    const linkItems = props.languages.map(function(lng) {
      return (
        <li className="download-item" key={'downloadbtn' + lng.locale}>
          <DownloadLink to={getDownloadUrl(lng.code)} onClick={() => btnDownloadClick()}>
            {t('common.languages.' + lng.locale)}
          </DownloadLink>
        </li>
      );
    });

    return <ul className="download-list">{linkItems}</ul>;
  };

  const btnDownloadClick = () => {
    setShowDownloadDropDown(!showDownloadDropDown);
  };

  const renderReadOnlineDropDown = () => {
    if (!showReadOnlineDropDown) return null;

    const linkItems = props.languages.map(function(lng) {
      return (
        <li className="download-item" key={'readonlineBtn' + lng.locale}>
          <button
            onClick={() => {
              btnReadOnlineLanguageClick(lng.code);
              btnReadOnlineClick();
            }}
          >
            {t('common.languages.' + lng.locale)}
          </button>
        </li>
      );
    });

    return <ul className="download-list">{linkItems}</ul>;
  };

  const btnReadOnlineClick = () => {
    setShowReadOnlineDropDown(!showReadOnlineDropDown);
  };

  const btnReadOnlineLanguageClick = language => {
    setReadonlineLanguageSelected(language);
    setShowGuide(true);
  };

  return (
    <>
      <div className="box-buttons">
        <img alt="logo" src={logo} />
        <div className="buttons-wrapper">
          <div className="btn-dropdown">
            <button id="btnDownload" className="buttons-wrapper__item" onClick={btnDownloadClick}>
              {t('common.download')}
            </button>
            {renderDownloadDropDown()}
          </div>
          <div className="btn-dropdown">
            <button id="btnRead" className="buttons-wrapper__item" onClick={btnReadOnlineClick}>
              {t('common.pdfViewer')}
            </button>
            {renderReadOnlineDropDown()}
          </div>
        </div>
      </div>
      <div className="small-text">
        <h3 className="text-center">{t('guide.disclaimerTitle')}</h3>
        <p>{t('guide.disclaimerText')}</p>
      </div>
      <PdfViewer show={showGuide} onClose={hideGuide} filepath={getDocumentUrl(readOnlineLanguageSelected)} />
    </>
  );
}

Guide.propTypes = {
  languages: PropTypes.array
};
