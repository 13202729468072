import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import logoUniversitat from '~/assets/images/catedra_microsoft.svg';
import logoFoundation from '~/assets/images/f29.svg';
import logoGarrigues from '~/assets/images/garrigues.svg';
import logoMicro from '~/assets/images/Microsoft-Logo.png';
import logoHm from '~/assets/images/hm.png';
import logoSanitas from '~/assets/images/sanitas.png';
import logoLaLiga from '~/assets/images/la_liga.png';
import { constants } from '~/shared/constants';
import './logos.scss';

export function Logos(props) {
  const [t] = useTranslation();
  return (
    <section className={`logos ${props.isSmall ? 'logos--small' : ''}`}>
      <article className="logos__group">
        <h2 className="logos__title">{t('footer.created')}</h2>
        <a href={constants.foundationUrl}>
          <img className="logos__img" alt="logo" src={logoFoundation} />
        </a>
      </article>
      <article className="logos__group">
        <h2 className="logos__title">{t('footer.colaboration')}</h2>
        <div className="logos__logo-group">
          <a href={constants.microsoftUrl} target="_blank" rel="noopener noreferrer">
            <img className="logos__img logos__img--small" alt="logo Microsoft" src={logoMicro} />
          </a>
          <a href={constants.garriguesUrl} target="_blank" rel="noopener noreferrer">
            <img className="logos__img logos__img--small" alt="logo Garrigues" src={logoGarrigues} />
          </a>
          <a href={constants.uvUrl} target="_blank" rel="noopener noreferrer">
            <img className="logos__img logos__img--small" alt="logo Universitat" src={logoUniversitat} />
          </a>
        </div>
      </article>
      <article className="logos__group">
        <h2 className="logos__title">{t('footer.publishers')}</h2>
        <div className="logos__logo-group">
          <img className="mr-3 logos__img logos__img--smallest" alt="logo HM hospitales" src={logoHm} />
          <img className="ml-3 mr-3 logos__img logos__img--small" alt="logo Sanitas" src={logoSanitas} />
          <img className="ml-3 logos__img logos__img--small" alt="logo La Liga" src={logoLaLiga} />
        </div>
      </article>
    </section>
  );
}

Logos.propTypes = {
  isSmall: PropTypes.bool
};
