import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Form, FormElement, Field, FieldArray } from '@progress/kendo-react-form';
import { TabStrip, TabStripTab } from '@progress/kendo-react-layout';
import { FormInput, FormTextArea, FormError } from '~/components/shared/form-fields';
import { FormUploadAuthenticated } from '~/components/shared/form-upload-authenticated';
import { useAction, HttpMethod } from '~/hooks/useAction';
import { useFetch } from '~/hooks/useFetch';
import { urlBuilder } from '~/shared/constants';
import { LoadingPanel } from '~/components/shared/LoadingPanel';
import { KendoLocalizationProvider } from '~/components/shared/KendoLocalizationProvider';
import { DeleteDatasetDialogConfirm } from './DeleteDatasetDialogConfirm';
import { StatisticsStateLabel } from './StatisticsStateLabel';
import { requestState } from '../ManageRequests/requestStates';
import { resourceField } from '../shared/resourceFormFields';

import './manageDatasetForm.scss';

const ManageDatasetForm = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const history = useHistory();

  const returnList = () => {
    history.replace('/platform/manage-datasets');
  };

  const { executeAction: executeGet, loading, response: responseDataItem } = useAction(
    {
      httpMethod: HttpMethod.GET
    },
    true,
    false
  );

  const { executeAction: executeGetUserInfo, response: responseUser } = useAction(
    {
      httpMethod: HttpMethod.GET
    },
    true,
    false
  );

  const { executeAction: executeAddNew, loading: loadingAddNew, response: responseAddNew } = useAction({
    httpMethod: HttpMethod.POST
  });

  const { executeAction: executeEdit, loading: loadingEdit, response: responseEdit } = useAction({
    httpMethod: HttpMethod.PUT
  });

  const [acceptState, setAcceptState] = useState(false);
  const [institutionPublisher, setInstitutionPublisher] = useState(false);

  const [productInDelete, setProductInDelete] = useState();

  const remove = dataItem => {
    setProductInDelete(Object.assign({}, dataItem));
  };

  const closeDeleteModal = reloadData => {
    setProductInDelete(undefined);
    if (reloadData) returnList();
  };

  const handleSubmit = dataset => {
    if (dataset.id) executeEdit({ url: urlBuilder.datasets.putAdminDatasets, data: dataset });
    else executeAddNew({ url: urlBuilder.datasets.postAdminDatasets, data: dataset });
  };

  useEffect(() => {
    if (!responseAddNew && !responseEdit) return;
    returnList();
  }, [responseAddNew, responseEdit]);

  useEffect(() => {
    if (responseUser != null) {
      setInstitutionPublisher(responseUser.data.iAmInstitutionPublisher);
    }
  }, [responseUser]);

  useEffect(() => {
    if (id) executeGet({ url: urlBuilder.datasets.getAdminDataset(id) });
    executeGetUserInfo({ url: urlBuilder.users.me });
  }, []);

  const defaultItemValue = {
    name: '',
    description: '',
    metadata: '',
    descriptionResources: [],
    metadataResources: [],
    nameResources: [],
    link: '',
    privacyFile: [],
    termsFile: []
  };

  const { loading: loadingLanguages, data: languagesList } = useFetch(urlBuilder.languages.getLanguages, false);

  const [tabSelected, setTabSelected] = useState(0);

  const resourceFilesField = (formRenderProps, language, nameField) => {
    if (!institutionPublisher) {
      return null;
    }
    var resources = formRenderProps.valueGetter(nameField);
    var field = resources?.find(d => d.languageId == language.id);
    var indexField = -1;
    if (field) {
      indexField = resources.indexOf(field);
    } else {
      indexField = resources.push({ languageId: language.id, languageCode: language.code, files: [] }) - 1;
    }

    return (
      <div className="mb-3">
        <Field
          name={`${nameField}[${indexField}].files`}
          component={FormUploadAuthenticated}
          saveUrl={urlBuilder.files.savePublisherTempFile}
          removeUrl={urlBuilder.files.deletePublisherTempFile}
          label={t(`platform.manage.columns.${nameField}`)}
        />
        <FieldArray
          name={nameField}
          component={FormError}
          validator={arrayFileResourcesValidator}
          label={t(`platform.manage.columns.${nameField}`)}
        />
      </div>
    );
  };

  const arrayFileResourcesValidator = value => {
    for (const fileResource of value) {
      if (fileResource.files?.length > 0) {
        const file = fileResource.files[0];

        if (file.status == 0 || file.validationErrors?.length > 0)
          return String.format(
            t('validators.errorFileStatus'),
            file.name,
            t(`common.languages.${fileResource.languageCode}`)
          );
        if (file.status == 3)
          return String.format(
            t('validators.fileUploading'),
            file.name,
            t(`common.languages.${fileResource.languageCode}`)
          );
        if (file.status == 6)
          return String.format(
            t('validators.fileDeleting'),
            file.name,
            t(`common.languages.${fileResource.languageCode}`)
          );
      }
    }
    return '';
  };

  return (
    <KendoLocalizationProvider>
      <div className="row justify-content-md-center manage-dataset-container">
        <div className="col-12">
          <h2 className="title">
            {!loading &&
              (responseDataItem && responseDataItem.data
                ? `${t('platform.manage.editDatasetTitle')}: ${responseDataItem.data.id}`
                : t('platform.manage.addDatasetTitle'))}
          </h2>
          {(!id || responseDataItem) && (
            <Form
              className="k-form"
              onSubmit={handleSubmit}
              initialValues={responseDataItem ? responseDataItem.data : defaultItemValue}
              render={formRenderProps => {
                return (
                  <FormElement>
                    {responseDataItem && responseDataItem.data && (
                      <div className="mb-3 state-grid">
                        <StatisticsStateLabel
                          state={requestState.NEW}
                          total={responseDataItem.data.totalAccessPetitions}
                        ></StatisticsStateLabel>
                        <StatisticsStateLabel
                          state={requestState.MORE_INFO}
                          total={responseDataItem.data.acceptedAccessPetitions}
                        ></StatisticsStateLabel>
                        <StatisticsStateLabel
                          state={requestState.ACCEPTED}
                          total={responseDataItem.data.requestedInfoAccessPetitions}
                        ></StatisticsStateLabel>
                        <StatisticsStateLabel
                          state={requestState.REJECTED}
                          total={responseDataItem.data.rejectedAccessPetitions}
                        ></StatisticsStateLabel>
                      </div>
                    )}
                    <TabStrip selected={tabSelected} onSelect={event => setTabSelected(event.selected)}>
                      {languagesList &&
                        languagesList.map(language => {
                          return (
                            <TabStripTab
                              key={`${language.id}_${language.code}`}
                              title={t(`common.languages.${language.code}`)}
                            >
                              {resourceField(
                                formRenderProps,
                                language,
                                'nameResources',
                                FormInput,
                                t,
                                languagesList,
                                'platform.manage.columns'
                              )}
                              {resourceField(
                                formRenderProps,
                                language,
                                'descriptionResources',
                                FormTextArea,
                                t,
                                languagesList,
                                'platform.manage.columns'
                              )}
                              {resourceField(
                                formRenderProps,
                                language,
                                'metadataResources',
                                FormTextArea,
                                t,
                                languagesList,
                                'platform.manage.columns'
                              )}
                              {resourceFilesField(formRenderProps, language, 'privacyFile')}
                              {resourceFilesField(formRenderProps, language, 'termsFile')}
                            </TabStripTab>
                          );
                        })}
                    </TabStrip>
                    <div className="mb-3">
                      <Field
                        name={'link'}
                        component={FormInput}
                        hintMessage={t('platform.manage.columns.linkMessage')}
                        label={t('platform.manage.columns.link')}
                      />
                    </div>
                    {acceptState && formRenderProps.visited && !formRenderProps.valid && (
                      <div className="k-form-field">
                        <div className={'k-messagebox k-messagebox-error'}>{t('validators.formInvalid')}</div>
                      </div>
                    )}
                    <div className="k-form-buttons">
                      <button className="health-data-button" onClick={returnList}>
                        {t('platform.manage.cancel')}
                      </button>
                      {responseDataItem && (
                        <button
                          className="health-data-button health-data-button__delete"
                          onClick={() => {
                            remove(responseDataItem.data);
                          }}
                        >
                          {t('platform.manage.remove')}
                        </button>
                      )}
                      <button
                        type={'submit'}
                        className="health-data-button"
                        disabled={!formRenderProps.allowSubmit}
                        onClick={() => {
                          setAcceptState(true);
                        }}
                      >
                        {t('platform.manage.save')}
                      </button>
                    </div>
                  </FormElement>
                );
              }}
            ></Form>
          )}
        </div>
      </div>
      {productInDelete && (
        <DeleteDatasetDialogConfirm dataset={productInDelete} close={closeDeleteModal}></DeleteDatasetDialogConfirm>
      )}
      {(loading || loadingAddNew || loadingEdit || loadingLanguages) && (
        <LoadingPanel idPortal=".manage-dataset-container" isFixed></LoadingPanel>
      )}
    </KendoLocalizationProvider>
  );
};

export { ManageDatasetForm };
