import React from 'react';

const RequestViewFormFieldCheck = ({ label, value, disabled }) => {
  return (
    <div className="mb-3">
      <input type="checkbox" checked={value} disabled={disabled} />
      <label className="custom-label ml-1">{label}</label>
    </div>
  );
};

export { RequestViewFormFieldCheck };
