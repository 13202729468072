import React, { useState, useEffect } from 'react';
import { Error, Hint } from '@progress/kendo-react-labels';
import { Upload } from '@progress/kendo-react-upload';
import authService from '~/components/api-authorization/AuthorizeService';
import { useTranslation } from 'react-i18next';

const requiredSymbol = ' *';

const FormUploadAuthenticated = fieldRenderProps => {
  const { t } = useTranslation();

  const [accessToken, setAccessToken] = useState('');

  const { value, validationMessage, visited, label, touched, showRequiredSymbol, ...others } = fieldRenderProps;

  useEffect(() => {
    async function getToken() {
      var token = await authService.getAccessToken();
      setAccessToken(token);
    }
    getToken();
  }, []);

  const beforeUpload = event => {
    const token = accessToken;
    event.headers = { Authorization: `Bearer ${token}` };
  };

  const beforeRemove = event => {
    const token = accessToken;
    event.headers = { Authorization: `Bearer ${token}` };
    const file = value && value.length > 0 && value[0];
    event.additionalData = { tempFile: file.tempFile };
  };

  const onAdd = event => {
    fieldRenderProps.onChange({ value: event.newState });
  };

  const onRemove = event => {
    fieldRenderProps.onChange({ value: event.newState });
  };

  const onProgress = event => {
    fieldRenderProps.onChange({ value: event.newState });
  };

  const onStatusChange = event => {
    if (event.newState && event.newState.length > 0) {
      var firstNewState = event.newState[0];
      var newValue = {};
      if (event.response?.status == 200) {
        newValue = { ...firstNewState, ...event.response?.response, progress: 100 };
      } else {
        newValue = { ...firstNewState, progress: 100 };
      }
      fieldRenderProps.onChange({ value: [newValue] });
    } else {
      fieldRenderProps.onChange({ value: event.newState });
    }
  };

  return (
    <div className="k-textbox-container">
      <label className="custom-label">{`${label}${showRequiredSymbol ? requiredSymbol : ''}`}</label>
      <Upload
        files={value}
        batch={false}
        multiple={false}
        withCredentials={true}
        restrictions={{
          allowedExtensions: ['.pdf'],
          maxFileSize: 10000000
        }}
        onBeforeUpload={beforeUpload}
        onBeforeRemove={beforeRemove}
        onAdd={onAdd}
        onRemove={onRemove}
        onProgress={onProgress}
        onStatusChange={onStatusChange}
        {...others}
      />
      <Hint>{t('validators.maxFileSize')}10MB</Hint>
      <Error>{visited && validationMessage && String.format(t(validationMessage), label)}</Error>
    </div>
  );
};

export { FormUploadAuthenticated };
