import React from 'react';
import { NavItem } from 'reactstrap';
import {
  getCurrentLanguageCode,
  changeCurrentLanguage,
  language,
  getNumberOfLanguagesAvailables
} from '~/services/app.i18n';

const LanguagesMenu = () => {
  if (getNumberOfLanguagesAvailables() > 1) {
    return (
      <ul className="navbar-nav languages-nav flex-grow">
        <NavItem>
          <span
            className={getCurrentLanguageCode() === language.Spanish.code ? 'nav-link active' : 'nav-link'}
            onClick={() => changeCurrentLanguage(language.Spanish.code)}
          >
            Es
          </span>
        </NavItem>
        <span className="separator">-</span>
        <NavItem>
          <span
            className={getCurrentLanguageCode() === language.English.code ? 'nav-link active' : 'nav-link'}
            onClick={() => changeCurrentLanguage(language.English.code)}
          >
            En
          </span>
        </NavItem>
      </ul>
    );
  } else {
    return null;
  }
};

export { LanguagesMenu };
