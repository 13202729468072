import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { useAction, HttpMethod } from '~/hooks/useAction';
import { urlBuilder } from '~/shared/constants';
import { LoadingPanel } from '~/components/shared/LoadingPanel';
import { requestState } from '../requestStates';

const ManageRequestDialogConfirm = ({ requestId, datasetLink, applicantEmail, state, close }) => {
  const { t } = useTranslation();

  const { executeAction, loading, response } = useAction({
    httpMethod: HttpMethod.PUT
  });

  const setState = (id, state) => {
    executeAction({ url: urlBuilder.petitions.putAccessPetition(id), data: { state: state } });
  };

  useEffect(() => {
    if (!response) return;
    close(true);
  }, [response]);

  const buttonStyle = {
    [requestState.ACCEPTED]: 'accept',
    [requestState.MORE_INFO]: 'moreInfo',
    [requestState.REJECTED]: 'reject'
  };

  const dialogMessage = {
    // eslint-disable-next-line react/display-name
    [requestState.ACCEPTED]: () => {
      return datasetLink && datasetLink.length > 0 ? (
        <>
          <h3>{t('platform.petition.dialogConfirm.acceptMessage1')}</h3>
          <p>{t('platform.petition.dialogConfirm.acceptMessage2')}</p>
        </>
      ) : (
        <>
          <h3>{t('platform.petition.dialogConfirm.acceptMessage1')}</h3>
          <p>{t('platform.petition.dialogConfirm.acceptMessage3')}</p>
          <p>
            <a href={`mailto:${applicantEmail}`}>{applicantEmail}</a>
          </p>
        </>
      );
    },
    // eslint-disable-next-line react/display-name
    [requestState.MORE_INFO]: () => {
      return (
        <>
          <h3>{t('platform.petition.dialogConfirm.moreInfoMessage1')}</h3>
          <p>{t('platform.petition.dialogConfirm.moreInfoMessage2')}</p>
          <p>
            <a href={`mailto:${applicantEmail}`}>{applicantEmail}</a>
          </p>
        </>
      );
    },
    // eslint-disable-next-line react/display-name
    [requestState.REJECTED]: () => {
      return (
        <>
          <h3>{t('platform.petition.dialogConfirm.rejectMessage1')}</h3>
          <p>{t('platform.petition.dialogConfirm.rejectMessage2')}</p>
        </>
      );
    }
  };

  return (
    <Dialog title="" onClose={() => close(false)}>
      <div style={{ margin: '25px', textAlign: 'center' }}>{dialogMessage[state]()}</div>
      <DialogActionsBar>
        <button className="health-data-button" onClick={() => close(false)}>
          {t('platform.petition.cancel')}
        </button>
        <button
          className={`health-data-button ${buttonStyle[state]}`}
          onClick={() => {
            setState(requestId, state);
          }}
        >
          {t('platform.petition.confirm')}
        </button>
      </DialogActionsBar>
      {loading && <LoadingPanel idPortal=".k-dialog"></LoadingPanel>}
    </Dialog>
  );
};

export { ManageRequestDialogConfirm };
