import React from 'react';
import { useTranslation } from 'react-i18next';
import { Dialog } from '@progress/kendo-react-dialogs';
import './userContactInfoCard.scss';

const UserContactInfoCard = ({ userInfo, close }) => {
  const { t } = useTranslation();
  return (
    <Dialog title={<b>{t('platform.users.columns.contactInfo')}</b>} onClose={() => close(false)}>
      {userInfo && (
        <div className={'card-container'}>
          <div className={'card-container-item'}>
            <i className="fas fa-user-tie"></i>
            <span>
              {userInfo.name} {userInfo.surname} ({userInfo.institution})
            </span>
          </div>
          <div className={'card-container-item'}>
            <i className="fas fa-envelope-square"></i>
            <a href={`mailto:${userInfo.email}`}>{userInfo.email}</a>
          </div>
          <div className={'card-container-item'}>
            <i className="fas fa-phone-square-alt"></i>
            <span>{userInfo.phoneNumber}</span>
          </div>
        </div>
      )}
    </Dialog>
  );
};

export { UserContactInfoCard };
