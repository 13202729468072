import React from 'react';
import { useTranslation } from 'react-i18next';
import { constants } from '~/shared/constants';
import { Logos, PolicyAndPrivacy } from '..';
import { LegalNotice } from '~/components/shared/LegalNotice';
import './footer.scss';

export function Footer() {
  const [t] = useTranslation();

  return (
    <>
      <footer className="footer main-container">
        <Logos />

        <p className="footer__info">
          {t('footer.contact')}
          <a href={`mailto:${constants.foundationMailInfo}?Subject=HealtData%2029%20Información`}>
            {constants.foundationMailInfo}
          </a>
        </p>
        <p>
          <a className="footer__link" href={constants.foundationUrl}>
            {t('footer.about')}
          </a>
          {' - '}
          <LegalNotice />
        </p>
      </footer>
    </>
  );
}
