import React from 'react';
import { useTranslation } from 'react-i18next';
import { StateLabel } from './StateLabel';

const ManageRequestsRow = ({ dataItem }) => {
  const { t } = useTranslation();
  return (
    <>
      <div className="grid-mobile-manage-requests__row">
        <StateLabel showIcon={true} state={dataItem.state}></StateLabel>
        <div className="grid-mobile-manage-requests__title text-overflow">{`[${dataItem.id}] ${dataItem.applicantName}`}</div>
      </div>
      <div className="grid-mobile-manage-requests__row" title={dataItem.datasetName}>
        <span className="grid-mobile-manage-requests__label">{t('platform.petitions.columns.datasetName')}: </span>
        <span className="grid-mobile-manage-requests__text  text-overflow">{dataItem.datasetName}</span>
      </div>
      <div className="grid-mobile-manage-requests__row" title={dataItem.created}>
        <span className="grid-mobile-manage-requests__label">{t('platform.petitions.columns.created')}: </span>
        <span className="grid-mobile-manage-requests__text  text-overflow">
          {String.stringToLocaleString(dataItem.created)}
        </span>
      </div>
    </>
  );
};

export { ManageRequestsRow };
