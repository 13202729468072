import React, { useCallback } from 'react';
import { ToastProvider } from 'react-toast-notifications';
import { Home } from '../modules';
import '../styles/app.scss';
import { changeCurrentLanguage } from '~/services/app.i18n';
import { Footer, Header, Notification } from './shared';
import { NavPlatformMenu } from '../components/shared/NavPlatformMenu';
export default function Playbook() {
  const onChangeLanguage = useCallback(language => {
    changeCurrentLanguage(language);
  }, []);

  return (
    <ToastProvider components={{ Toast: Notification }} autoDismiss={true} autoDismissTimeout={5000}>
      <NavPlatformMenu />
      <Home />
      <Footer />
    </ToastProvider>
  );
}
