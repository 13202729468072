import { getCookie, setCookie } from './cookies';

const cultureCookieName = '.AspNetCore.Culture';
const cultureCookieExpireDays = 365;

export default {
  name: 'aspCookie',

  lookup(options) {
    let found = options.defaultLanguage ?? 'es-ES';
    if (typeof document !== 'undefined') {
      var cookie = getCookie(cultureCookieName);
      if (cookie) {
        found = cookie.split('|')[0].split('=')[1] ?? found;
      }
    }

    return found;
  },

  cacheUserLanguage(lng) {
    setCookie(cultureCookieName, `c=${lng}|uic=${lng}`, cultureCookieExpireDays);
  }
};
