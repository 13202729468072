import React, { useEffect, useState } from 'react';
import { GridColumn } from '@progress/kendo-react-grid';
import { Checkbox, Input } from '@progress/kendo-react-inputs';
import { Button } from '@progress/kendo-react-buttons';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useAction, HttpMethod } from '~/hooks/useAction';
import { urlBuilder } from '~/shared/constants';
import { getCurrentLanguageLocale } from '~/services/app.i18n';
import { CustomGrid } from '../shared/CustomGrid';
import { PublicDatasetsRow } from './PublicDatasets/PublicDatasetsRow';

import './publicDatasets.scss';

const PublicDatasets = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const [searchText, setSearchText] = useState('');
  const [searchIndividual, setSearchIndividual] = useState(true);
  const [searchInstitution, setSearchInstitution] = useState(true);

  const { executeAction: executeSearch, loading: loadingSearch, response: responseSearch } = useAction(
    {
      httpMethod: HttpMethod.GET
    },
    false,
    false
  );

  const onSearch = () => {
    executeSearch({
      url: urlBuilder.datasets.getPublicDatasets(
        searchText,
        getCurrentLanguageLocale(),
        searchIndividual,
        searchInstitution
      )
    });
  };

  useEffect(() => {
    onSearch();
  }, [t]);

  useEffect(() => {
    onSearch();
  }, [searchIndividual, searchInstitution]);

  const handleChange = event => {
    setSearchText(event.target.value);
  };
  const handleChangeIndividual = event => {
    setSearchIndividual(event.value);
  };

  const handleChangeInstitution = event => {
    setSearchInstitution(event.value);
  };
  return (
    <>
      <h1>{t('platform.search.title')}</h1>
      <div className="box__content">
        <p>{t('platform.search.description')}</p>
      </div>
      <div className="k-form search-filters">
        <div className="search-group">
          <Input
            label={t('platform.search.searchDatasets')}
            maxLength={255}
            value={searchText}
            onChange={handleChange}
          ></Input>
          <Button onClick={onSearch} icon="search"></Button>
        </div>
        <div className="form-group">
          <label className="k-label">Publisher</label>
          <div className="checkbox-group">
            <Checkbox label={'Individual'} value={searchIndividual} onChange={handleChangeIndividual}></Checkbox>
            <Checkbox label={'Institution'} value={searchInstitution} onChange={handleChangeInstitution}></Checkbox>
          </div>
        </div>
      </div>
      <CustomGrid
        loading={loadingSearch}
        data={responseSearch && responseSearch.data}
        sortable={false}
        filterable={false}
        className={'grid-datasets'}
      >
        <GridColumn
          cell={props => (
            <td
              onClick={() => {
                history.push('/platform/datasets/detail/' + props.dataItem.id);
              }}
            >
              <PublicDatasetsRow dataItem={props.dataItem}></PublicDatasetsRow>
            </td>
          )}
        ></GridColumn>
      </CustomGrid>
    </>
  );
};

export { PublicDatasets };
