import PropTypes from 'prop-types';
import React from 'react';

export function DownloadLink({ to, children, ...rest }) {
  return (
    <a {...rest} href={to} download>
      {children}
    </a>
  );
}

DownloadLink.propTypes = {
  to: PropTypes.string,
  children: PropTypes.node
};
