import React from 'react';
import { createPortal } from 'react-dom';
import './loadingPanel.scss';

const LoadingPanel = ({ idPortal, isFixed }) => {
  const loadingPanel = (
    <div className={isFixed ? 'k-loading-mask fixed' : 'k-loading-mask'}>
      <span className="k-loading-text">Loading</span>
      <div className="k-loading-image"></div>
      <div className="k-loading-color"></div>
    </div>
  );

  const gridContent = document && idPortal && document.querySelector(idPortal);
  return gridContent ? createPortal(loadingPanel, gridContent) : loadingPanel;
};

export { LoadingPanel };
