import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { Input } from '@progress/kendo-react-inputs';
import useCustomForm from '~/hooks/useCustomForm';
import { useAction, HttpMethod } from '~/hooks/useAction';
import { LoadingPanel } from '~/components/shared/LoadingPanel';
import { urlBuilder } from '~/shared/constants';
import { requiredValidatorMessage } from '~/components/shared/form-validators';
import './editDatasetDialogForm.scss';

const EditDatasetDialogForm = ({ dataset, close }) => {
  const { t } = useTranslation();

  const { executeAction: executeAddNew, loading: loadingAddNew, response: responseAddNew } = useAction({
    httpMethod: HttpMethod.POST
  });

  const { executeAction: executeEdit, loading: loadingEdit, response: responseEdit } = useAction({
    httpMethod: HttpMethod.PUT
  });

  const { values, handleChange, handleSubmit } = useCustomForm({
    initialValues: dataset,
    onSubmit: ({ values }) => {
      save(values);
    }
  });

  const save = dataset => {
    if (dataset.id) executeEdit({ url: urlBuilder.datasets.putAdminDatasets, data: dataset });
    else executeAddNew({ url: urlBuilder.datasets.postAdminDatasets, data: dataset });
  };

  useEffect(() => {
    if (!responseAddNew && !responseEdit) return;
    close(true);
  }, [responseAddNew, responseEdit]);

  const InputField = (nameField, maxLength) => {
    return (
      <Input
        name={nameField}
        style={{ width: '100%' }}
        label={t(`platform.manage.columns.${nameField}`)}
        value={values[nameField]}
        onChange={handleChange}
        required={true}
        validationMessage={requiredValidatorMessage(t(`platform.manage.columns.${nameField}`), t)}
        validityStyles={false}
        maxLength={maxLength}
      />
    );
  };

  return (
    <Dialog
      title={dataset.id ? t('platform.manage.editDatasetTitle') : t('platform.manage.addDatasetTitle')}
      onClose={() => close(false)}
      width={600}
    >
      <form onSubmit={handleSubmit} className="k-form">
        <div className="mb-3">{InputField('name', 255)}</div>
        <div className="mb-3">{InputField('description', 1024)}</div>
        <div className="mb-3">{InputField('metadata', 1024)}</div>
        <div className="mb-3">{InputField('link', 255)}</div>
        <DialogActionsBar>
          <button className="health-data-button" onClick={() => close(false)}>
            {t('platform.manage.cancel')}
          </button>
          <button className="health-data-button health-data-button__action" type="submit">
            {t('platform.manage.save')}
          </button>
        </DialogActionsBar>
      </form>
      {(loadingAddNew || loadingEdit) && <LoadingPanel idPortal=".k-form"></LoadingPanel>}
    </Dialog>
  );
};

export { EditDatasetDialogForm };
