import React from 'react';
import { Field, FieldArray } from '@progress/kendo-react-form';
import { FormError } from '~/components/shared/form-fields';

const resourceField = (
  formRenderProps,
  language,
  nameField,
  component,
  t,
  languagesList,
  columnsTranslationKey,
  disabled,
  showRequiredSymbol
) => {
  var resources = formRenderProps.valueGetter(nameField);
  var field = resources.find(d => d.languageId === language.id);
  var indexField = -1;
  if (field) {
    indexField = resources.indexOf(field);
  } else {
    indexField = resources.push({ languageId: language.id, languageCode: language.code, text: '' }) - 1;
  }

  return (
    <div className="mb-3">
      <Field
        name={`${nameField}[${indexField}].text`}
        component={component}
        rows={6}
        label={t(`${columnsTranslationKey}.${nameField}`)}
        showRequiredSymbol={
          (showRequiredSymbol !== undefined && showRequiredSymbol) ||
          (showRequiredSymbol === undefined && language.isDefault)
        }
        disabled={disabled || false}
      />
      <FieldArray
        name={nameField}
        component={FormError}
        validator={value => arrayResourcesValidator(value, t, languagesList)}
        label={t(`${columnsTranslationKey}.${nameField}`)}
      />
    </div>
  );
};

const arrayResourcesValidator = (value, t, languagesList) => {
  if (languagesList) {
    var defaultLanguage = languagesList.find(l => l.isDefault);
    var fieldText = value.find(r => r.languageId === defaultLanguage.id);
    if (fieldText.text.length < 1) {
      return `{0} ${String.format(
        t('validators.fieldLanguageRequired'),
        t(`common.languages.${defaultLanguage.code}`)
      )}`;
    }
  }
  return '';
};

export { resourceField };
