import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Form, FormElement, Field } from '@progress/kendo-react-form';
import { TabStrip, TabStripTab } from '@progress/kendo-react-layout';
import { FormInput, FormTextArea, FormCheckbox } from '~/components/shared/form-fields';
import { useAction, HttpMethod } from '~/hooks/useAction';
import { useFetch } from '~/hooks/useFetch';
import { KendoLocalizationProvider } from '~/components/shared/KendoLocalizationProvider';
import { urlBuilder } from '~/shared/constants';
import { LoadingPanel } from '~/components/shared/LoadingPanel';
import { resourceField } from '../shared/resourceFormFields';

import './manageLaboratoryForm.scss';

const ManageLaboratoryForm = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const history = useHistory();

  const returnList = useCallback(() => {
    history.replace('/platform/manage-laboratories');
  }, [history]);

  const { executeAction: executeGet, loading, response: responseDataItem } = useAction(
    {
      httpMethod: HttpMethod.GET
    },
    true,
    false
  );

  useEffect(() => {
    if (id) executeGet({ url: urlBuilder.laboratories.getLaboratory(id) });
  }, [executeGet, id]);

  const { executeAction: executeEdit, loading: loadingEdit, response: responseEdit } = useAction({
    httpMethod: HttpMethod.PUT
  });

  const [acceptState, setAcceptState] = useState(false);

  const handleSubmit = ({ canRequestLaboratory }) =>
    executeEdit({ url: urlBuilder.laboratories.putLaboratory(id), data: { canRequestLaboratory } });

  useEffect(() => {
    if (!responseEdit) return;
    returnList();
  }, [responseEdit, returnList]);

  const defaultItemValue = {
    institution: '',
    publisherName: '',
    publisherSurname: '',
    nameResources: [],
    descriptionResources: [],
    canRequestLaboratory: false
  };

  const { loading: loadingLanguages, data: languagesList } = useFetch(urlBuilder.languages.getLanguages, false);

  const [tabSelected, setTabSelected] = useState(0);

  return (
    <KendoLocalizationProvider>
      <div className="row justify-content-md-center manage-dataset-container">
        <div className="col-12">
          <h2 className="title">
            {!loading &&
              (responseDataItem && responseDataItem.data
                ? `${t('platform.manage.editDatasetTitle')}: ${responseDataItem.data.id}`
                : '')}
          </h2>
          {(!id || responseDataItem) && (
            <Form
              className="k-form"
              onSubmit={handleSubmit}
              initialValues={responseDataItem ? responseDataItem.data : defaultItemValue}
              render={formRenderProps => {
                return (
                  <FormElement>
                    <TabStrip selected={tabSelected} onSelect={event => setTabSelected(event.selected)}>
                      {languagesList &&
                        languagesList.map(language => {
                          return (
                            <TabStripTab
                              key={`${language.id}_${language.code}`}
                              title={t(`common.languages.${language.code}`)}
                            >
                              <div className="mb-3">
                                <Field
                                  name={'institution'}
                                  component={FormInput}
                                  label={t('platform.laboratories.manage.columns.institution')}
                                  disabled={true}
                                />
                              </div>
                              <div className="mb-3">
                                <Field
                                  name={'publisherName'}
                                  component={FormInput}
                                  label={t('platform.laboratories.manage.columns.publisherName')}
                                  disabled={true}
                                />
                              </div>
                              <div className="mb-3">
                                <Field
                                  name={'publisherSurname'}
                                  component={FormInput}
                                  label={t('platform.laboratories.manage.columns.publisherSurname')}
                                  disabled={true}
                                />
                              </div>
                              {resourceField(
                                formRenderProps,
                                language,
                                'nameResources',
                                FormInput,
                                t,
                                languagesList,
                                'platform.laboratories.manage.columns',
                                true,
                                false
                              )}
                              {resourceField(
                                formRenderProps,
                                language,
                                'descriptionResources',
                                FormTextArea,
                                t,
                                languagesList,
                                'platform.laboratories.manage.columns',
                                true,
                                false
                              )}
                              <div className="mb-3">
                                <Field
                                  name={'canRequestLaboratory'}
                                  component={FormCheckbox}
                                  label={t('platform.laboratories.manage.columns.canRequestLaboratory')}
                                />
                              </div>
                            </TabStripTab>
                          );
                        })}
                    </TabStrip>
                    {acceptState && formRenderProps.visited && !formRenderProps.valid && (
                      <div className="k-form-field">
                        <div className={'k-messagebox k-messagebox-error'}>{t('validators.formInvalid')}</div>
                      </div>
                    )}
                    <div className="k-form-buttons">
                      <button className="health-data-button" onClick={returnList}>
                        {t('platform.manage.cancel')}
                      </button>
                      <button
                        type={'submit'}
                        className="health-data-button"
                        disabled={!formRenderProps.allowSubmit}
                        onClick={() => {
                          setAcceptState(true);
                        }}
                      >
                        {t('platform.manage.save')}
                      </button>
                    </div>
                  </FormElement>
                );
              }}
            ></Form>
          )}
        </div>
      </div>
      {(loading || loadingEdit || loadingLanguages) && (
        <LoadingPanel idPortal=".manage-dataset-container" isFixed></LoadingPanel>
      )}
    </KendoLocalizationProvider>
  );
};

export { ManageLaboratoryForm };
