import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { useAction, HttpMethod } from '~/hooks/useAction';
import { urlBuilder } from '~/shared/constants';
import { LoadingPanel } from '~/components/shared/LoadingPanel';

const DeleteDatasetDialogConfirm = ({ dataset, close }) => {
  const { t } = useTranslation();

  const { executeAction, loading, response } = useAction({
    httpMethod: HttpMethod.DELETE
  });

  const onDelete = () => {
    executeAction({ url: urlBuilder.datasets.deleteAdminDatasets(dataset.id), data: { id: dataset.id } });
  };

  useEffect(() => {
    if (!response) return;
    close(true);
  }, [response]);

  return (
    <Dialog title={t('platform.manage.deleteDatasetTitle')} onClose={() => close(false)}>
      <p style={{ margin: '25px', textAlign: 'center' }}>{t('platform.manage.deleteDatasetMessage')}</p>
      <DialogActionsBar>
        <button className="health-data-button" onClick={() => close(false)}>
          {t('platform.manage.cancel')}
        </button>
        <button className="health-data-button health-data-button__delete" onClick={onDelete}>
          {t('platform.manage.remove')}
        </button>
      </DialogActionsBar>
      {loading && <LoadingPanel idPortal=".k-dialog"></LoadingPanel>}
    </Dialog>
  );
};

export { DeleteDatasetDialogConfirm };
