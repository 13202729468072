import React, { Component } from 'react';
import { NavItem, NavLink } from 'reactstrap';
import authService from './AuthorizeService';
import authorizeRole from './AuthorizeRole';

export class AuthorizeNavLink extends Component {
  static displayName = AuthorizeNavLink.name;

  constructor(props) {
    super(props);

    this.state = {
      isAuthorize: false
    };
  }

  componentDidMount() {
    this._subscription = authService.subscribe(() => this.authenticationChanged());
    this.populateAuthenticationState();
  }

  componentWillUnmount() {
    authService.unsubscribe(this._subscription);
  }

  render() {
    const { isAuthorize } = this.state;
    if (isAuthorize) {
      const { children, requiredRoles, ...rest } = this.props;
      return (
        <NavItem>
          <NavLink {...rest}>{children}</NavLink>
        </NavItem>
      );
    }

    return <></>;
  }

  async populateAuthenticationState() {
    const authenticated = await authService.isAuthenticated();
    if (authenticated) {
      const { requiredRoles } = this.props;
      var hasAuthorizedRole = !requiredRoles;

      for (var i = 0; i < requiredRoles.length && !hasAuthorizedRole; i++) {
        hasAuthorizedRole = await authorizeRole.isInRole(requiredRoles[i]);
      }
      this.setState({ isAuthorize: hasAuthorizedRole });
    }
  }

  async authenticationChanged() {
    this.setState({ isAuthorize: false });
    await this.populateAuthenticationState();
  }
}
