import React, { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, Link } from 'react-router-dom';
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { LoginMenu } from '../api-authorization/LoginMenu';
import { AuthorizeNavLink } from '../api-authorization/AuthorizeNavItem';
import logoImage from '~/assets/images/healthdata29.svg';
import { LanguagesMenu } from './LanguagesMenu';
import './navMenu.scss';
import { SettingsContext } from '~/contexts/SettingsProvider';

const NavPlatformMenu = () => {
  const [t] = useTranslation();
  const { pathname } = useLocation();
  const [collapsed, setCollapsed] = useState(true);
  const { laboratoriesFeatureEnabled } = useContext(SettingsContext);

  const toggleNavbar = () => {
    setCollapsed(!collapsed);
  };

  const closeNavbar = () => {
    setCollapsed(true);
  };

  return (
    <header className="health-data-header">
      <Navbar className="navbar navbar-expand-lg navbar-toggleable-lg" light>
        <Container>
          <div className="navbar-container">
            <NavbarToggler onClick={toggleNavbar} className="mr-2" />
            <NavbarBrand tag={Link} to="/">
              <img alt="logo" src={logoImage} />
            </NavbarBrand>
          </div>
          <Collapse className="d-lg-inline-flex flex-lg-row-reverse" isOpen={!collapsed} navbar>
            <ul className="navbar-nav flex-grow">
              <NavItem onClick={closeNavbar}>
                <NavLink tag={Link} to="/playbook" active={pathname === '/playbook'}>
                  {t('platform.menu.playbook')}
                </NavLink>
              </NavItem>
              <NavItem onClick={closeNavbar}>
                <NavLink tag={Link} to="/platform" active={pathname === '/platform'}>
                  {t('platform.menu.home')}
                </NavLink>
              </NavItem>
              <NavItem onClick={closeNavbar}>
                <NavLink
                  tag={Link}
                  to="/platform/datasets"
                  active={pathname === '/platform/datasets' || pathname.startsWith('/platform/datasets/')}
                >
                  {t('platform.menu.searchDatasets')}
                </NavLink>
              </NavItem>
              <AuthorizeNavLink
                onClick={closeNavbar}
                requiredRoles={['Publisher', 'PlatformAdmin']}
                tag={Link}
                to="/platform/manage-datasets"
                active={pathname.startsWith('/platform/manage-datasets')}
              >
                {t('platform.menu.manageDatasets')}
              </AuthorizeNavLink>
              <AuthorizeNavLink
                onClick={closeNavbar}
                requiredRoles={['Publisher', 'PlatformAdmin']}
                tag={Link}
                to="/platform/manage-requests"
                active={pathname.startsWith('/platform/manage-requests')}
              >
                {t('platform.menu.manageRequests')}
              </AuthorizeNavLink>
              <AuthorizeNavLink
                onClick={closeNavbar}
                requiredRoles={['PlatformAdmin']}
                tag={Link}
                to="/platform/manage-users"
                active={pathname === '/platform/manage-users'}
              >
                {t('platform.menu.manageUsers')}
              </AuthorizeNavLink>
              {laboratoriesFeatureEnabled && (
                <AuthorizeNavLink
                  onClick={closeNavbar}
                  requiredRoles={['PlatformAdmin']}
                  tag={Link}
                  to="/platform/manage-laboratories"
                  active={pathname === '/platform/manage-laboratories'}
                >
                  {t('platform.menu.manageLaboratories')}
                </AuthorizeNavLink>
              )}
              <LoginMenu></LoginMenu>
            </ul>
          </Collapse>
          <LanguagesMenu></LanguagesMenu>
        </Container>
      </Navbar>
    </header>
  );
};

export { NavPlatformMenu };
