import PropTypes from 'prop-types';
import React from 'react';

export function Section(props) {
  return (
    <section className={props.className + ' box-wrapper text-center'} id={props.id}>
      <h2 className="box__title">{props.title}</h2>
      {props.children}
    </section>
  );
}

Section.propTypes = {
  title: PropTypes.string,
  id: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string
};
