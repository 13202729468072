import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';
import { useTranslation } from 'react-i18next';
import { PolicyAndPrivacy, useNotification } from '~/playbook/shared';
import { Section } from '../../playbook/shared';
import './information.scss';

export function Information() {
  const [t] = useTranslation();
  const [notifyError, notifySuccess] = useNotification();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [institution, setInstitution] = useState('');
  const [text, setText] = useState('');

  const handleSubmit = event => {
    const data = {
      name: name,
      email: email,
      institution: institution,
      text: text
    };
    fetch('/api/message', {
      method: 'post',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    }).then(response => {
      if (response.status === 200) {
        notifySuccess(t('notification.sendOK'));
      } else {
        notifyError(t('notification.sendKO'));
      }
    });
    setName('');
    setEmail('');
    setInstitution('');
    setText('');
    event.preventDefault();
    event.stopPropagation();
  };

  const onChangeName = event => {
    setName(event.currentTarget.value);
  };

  const onChangeEmail = event => {
    setEmail(event.currentTarget.value);
  };

  const onChangeInstitution = event => {
    setInstitution(event.currentTarget.value);
  };

  const onChangeText = event => {
    setText(event.currentTarget.value);
  };

  return (
    <>
      <Section className="information-section" title={t('home.moreInformation')} id="contact">
        <div className="box__content">
          <p>
            {t('information.info1')}
            <span className="health-text">{t('home.paragrah2_2_1')}</span>
            <span className="health-data-29-text">{t('home.paragrah2_2_2')}</span>
            {t('information.info2')}
          </p>
        </div>
        <form onSubmit={handleSubmit} className="form-wrapper information-form">
          <div className="input-group">
            <Form.Control type="text" required value={name} onChange={onChangeName} />
            <Form.Label>{t('information.name')}</Form.Label>
          </div>
          <div className="input-group">
            <Form.Control type="email" required value={email} onChange={onChangeEmail} />
            <Form.Label>{t('information.email')}</Form.Label>
          </div>
          <div className="input-group">
            <Form.Control type="text" onChange={onChangeInstitution} value={institution} />
            <Form.Label>{t('information.institution')}</Form.Label>
          </div>
          <div className="input-group textarea-group">
            <Form.Control as="textarea" rows="4" onChange={onChangeText} value={text} />
            <Form.Label>{t('information.message')}</Form.Label>
          </div>
          <div className="text-left">
            <button id="btnSend" className="buttons-wrapper__item buttons-wrapper__item--small">
              {t('information.send')}
            </button>
          </div>
          <div className="small-text">
            <p>{t('information.privacy')}</p>
          </div>
        </form>
        <div className="form-wrapper">
          <PolicyAndPrivacy isFooter={false} />
        </div>
      </Section>
    </>
  );
}
