import React from 'react';
import { useTranslation } from 'react-i18next';
import { Field } from '@progress/kendo-react-form';
import { FormInput, FormDatePicker, FormDropDownList } from '~/components/shared/form-fields';
import { emailValidator } from '~/components/shared/form-validators';

const FormAuthorizedElement = ({ typesID }) => {
  const { t } = useTranslation();

  return (
    <>
      <p className="health-data-subtitle">
        <b>{t('platform.requestDataset.titleAuthorized')}</b>
        <br></br>
        <span className="health-data-subtitle description">{t('platform.requestDataset.descriptionAuthorized')}</span>
      </p>
      <div className="mb-3">
        <Field
          name={'authorized.name'}
          component={FormInput}
          label={t('platform.requestDataset.fields.authorized.name')}
        />
      </div>
      <div className="mb-3">
        <Field
          name={'authorized.title'}
          component={FormInput}
          label={t('platform.requestDataset.fields.authorized.title')}
        />
      </div>
      <div className="mb-3">
        <Field
          name={'authorized.position'}
          component={FormInput}
          label={t('platform.requestDataset.fields.authorized.position')}
        />
      </div>
      <div className="mb-3">
        <Field
          name={'authorized.affiliation'}
          component={FormInput}
          label={t('platform.requestDataset.fields.authorized.affiliation')}
        />
      </div>
      <div className="mb-3">
        <Field
          name={'authorized.institutionalEmail'}
          component={FormInput}
          label={t('platform.requestDataset.fields.authorized.institutionalEmail')}
          validator={emailValidator}
        />
      </div>
      <div className="mb-3">
        <Field
          name={'authorized.address'}
          component={FormInput}
          label={t('platform.requestDataset.fields.authorized.address')}
        />
      </div>
      <fieldset className={'k-form-fieldset'}>
        <legend className={'k-form-legend'}>
          {t('platform.requestDataset.fields.authorized.identificationDocumentLegend')}
        </legend>
        <div className="mb-3">
          <Field
            key={'authorized.identificationDocument'}
            id={'authorized.identificationDocument'}
            name={'authorized.identificationDocument'}
            label={t('platform.requestDataset.fields.authorized.identificationDocument')}
            component={FormDropDownList}
            data={typesID}
            textField={'name'}
            valueField={'id'}
          />
        </div>
        <div className="mb-3">
          <Field
            name={'authorized.identificationNumber'}
            component={FormInput}
            label={t('platform.requestDataset.fields.authorized.identificationNumber')}
          />
        </div>
        <div className="mb-3">
          <Field
            name={'authorized.countryID'}
            component={FormInput}
            label={t('platform.requestDataset.fields.authorized.countryID')}
          />
        </div>
        <div className="mb-3">
          <Field
            name={'authorized.dateOfIssue'}
            component={FormDatePicker}
            label={t('platform.requestDataset.fields.authorized.dateOfIssue')}
          />
        </div>
        <div className="mb-3">
          <Field
            name={'authorized.dueDate'}
            component={FormDatePicker}
            label={t('platform.requestDataset.fields.authorized.dueDate')}
          />
        </div>
      </fieldset>
      <p className="health-data-subtitle notes">{t('platform.requestDataset.noteAuthorized')}</p>
    </>
  );
};

export { FormAuthorizedElement };
