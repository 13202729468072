import { useEffect, useState } from 'react';
import useAxios from 'axios-hooks';
import authService from '~/components/api-authorization/AuthorizeService';

const useFetch = (url, isAuthenticate) => {
  const [{ data, loading, error }, execute] = useAxios(
    {
      url: url,
      method: 'GET'
    },
    { manual: true }
  );

  const [isInit, executeFetch] = useState(false);

  useEffect(() => {
    executeActionAsync();
  }, [isInit]);

  const executeActionAsync = async () => {
    const token = isAuthenticate ? await authService.getAccessToken() : undefined;
    execute({ headers: !token ? {} : { Authorization: `Bearer ${token}` } });
  };

  const reload = () => {
    executeFetch(!isInit);
  };

  return { reload, loading, error, data };
};

export { useFetch };
