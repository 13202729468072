import React from 'react';
import { useTranslation } from 'react-i18next';

const statesClass = {
  0: 'k-messagebox-info',
  1: 'k-messagebox-warning',
  2: 'k-messagebox-success',
  3: 'k-messagebox-error'
};

const statesIcons = {
  0: 'k-i-plus-outline',
  1: 'k-i-information',
  2: 'k-i-check-outline',
  3: 'k-i-close-outline'
};

const StateLabel = ({ state, showIcon = false }) => {
  const { t } = useTranslation();
  return (
    <div className={`k-messagebox ${statesClass[state]}`}>
      {showIcon ? <span className={`k-icon ${statesIcons[state]}`} /> : t(`platform.petitions.states.${state}`)}
    </div>
  );
};

export { StateLabel };
