import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import {
  confirmRegisterUserMessage,
  confirmRegisterUserMessageWithoutInstitution,
  enableUserMessage,
  enableUserMessageWithoutInstitution,
  disableUserMessage,
  disableUserMessageWithoutInstitution
} from '~/components/shared/form-validators';
import { useAction, HttpMethod } from '~/hooks/useAction';
import { urlBuilder } from '~/shared/constants';
import { LoadingPanel } from '~/components/shared/LoadingPanel';

const UserActions = {
  Confirm: 1,
  Enable: 2,
  Disable: 3,
  ConfirmWithoutInstitution: 4,
  EnableWithoutInstitution: 5,
  DisableWithoutInstitution: 6
};

const UserActionsDialogConfirm = ({ actionData, close }) => {
  const { t } = useTranslation();

  const UserActionMessage = {
    1: confirmRegisterUserMessage(actionData.user.name, actionData.user.institutionRegister, t),
    2: enableUserMessage(actionData.user.name, actionData.user.institutionRegister, t),
    3: disableUserMessage(actionData.user.name, actionData.user.institutionRegister, t),
    4: confirmRegisterUserMessageWithoutInstitution(actionData.user.name, t),
    5: enableUserMessageWithoutInstitution(actionData.user.name, t),
    6: disableUserMessageWithoutInstitution(actionData.user.name, t)
  };

  const { executeAction, loading, response } = useAction({ httpMethod: HttpMethod.PUT });

  const onAccept = () => {
    if (actionData.action == UserActions.Disable || actionData.action == UserActions.DisableWithoutInstitution)
      executeAction({
        url: urlBuilder.users.disableUser(actionData.user.id),
        data: { id: actionData.user.id }
      });
    else
      executeAction({
        url: urlBuilder.users.registerConfirmationUser(actionData.user.id),
        data: { id: actionData.user.id }
      });
  };

  useEffect(() => {
    if (!response) return;
    close(true);
  }, [response]);

  return (
    <Dialog title={t('platform.users.confirmRegisterUserTitle')} onClose={() => close(false)}>
      <p style={{ margin: '25px', textAlign: 'center' }}>{UserActionMessage[actionData.action]}</p>
      <DialogActionsBar>
        <button className="health-data-button" onClick={() => close(false)}>
          {t('platform.users.cancel')}
        </button>
        <button className="health-data-button health-data-button__action" onClick={onAccept}>
          {t('platform.users.accept')}
        </button>
      </DialogActionsBar>
      {loading && <LoadingPanel idPortal=".k-dialog"></LoadingPanel>}
    </Dialog>
  );
};

export { UserActionsDialogConfirm, UserActions };
