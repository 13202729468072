import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { urlBuilder } from '~/shared/constants';
import { getCurrentLanguageLocale } from '~/services/app.i18n';
import { LoadingPanel } from '~/components/shared/LoadingPanel';
import { RequestViewPersonalData } from './RequestViewPersonalData';
import { RequestViewProjectData } from './RequestViewProjectData';
import { RequestViewFormFieldCheck } from './RequestViewFormFieldCheck';
import { StateLabel } from './StateLabel';
import { useAction, HttpMethod } from '~/hooks/useAction';
import { requestState } from './requestStates';
import { ManageRequestDialogConfirm } from './Dialogs/ManageRequestDialogConfirm';
import { SettingsContext } from '~/contexts/SettingsProvider';

const RequestViewForm = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const history = useHistory();
  const { laboratoriesFeatureEnabled } = useContext(SettingsContext);

  const [requestInConfirm, setRequestInConfirm] = useState();

  const setRequestState = (request, state) => {
    setRequestInConfirm({ request, state });
  };

  const closeDialogConfirm = reloadData => {
    setRequestInConfirm(undefined);
    if (reloadData) onReturn();
  };

  const onReturn = () => {
    history.replace('/platform/manage-requests');
  };

  const { executeAction: executeGet, loading, response: responseGet } = useAction(
    {
      httpMethod: HttpMethod.GET
    },
    true,
    false
  );

  useEffect(() => {
    const onGetDataset = () => {
      executeGet({ url: urlBuilder.petitions.getAccessPetition(id, getCurrentLanguageLocale()) });
    };
    onGetDataset();
  }, [executeGet, id, t]);

  return (
    <div className="row justify-content-md-center loading-container">
      <div className="col-md-8">
        <h1 className="health-data-title__big">
          {t('platform.petition.title')}: {responseGet && responseGet.data && responseGet.data.id}
        </h1>

        {responseGet && responseGet.data && (
          <>
            <StateLabel state={responseGet.data.state}></StateLabel>
            <p className="health-data-subtitle">{t('platform.petition.dataset')}</p>
            <p className="health-data-subtitle data-set-name">{responseGet.data.datasetName}</p>
            <div className="k-form">
              <RequestViewPersonalData
                name={'applicant'}
                dataItem={responseGet.data.applicant}
              ></RequestViewPersonalData>
              <RequestViewPersonalData
                name={'representative'}
                dataItem={responseGet.data.representative}
              ></RequestViewPersonalData>
              <RequestViewPersonalData
                name={'authorized'}
                dataItem={responseGet.data.authorized}
              ></RequestViewPersonalData>
              <RequestViewPersonalData name={'student'} dataItem={responseGet.data.student}></RequestViewPersonalData>
              <RequestViewProjectData dataItem={responseGet.data}></RequestViewProjectData>
              {laboratoriesFeatureEnabled && (
                <RequestViewFormFieldCheck
                  label={t('platform.petition.fields.wantLaboratory')}
                  value={responseGet.data.wantLaboratory}
                  disabled={true}
                ></RequestViewFormFieldCheck>
              )}
              <div className="k-form-buttons">
                <button className="health-data-button" onClick={onReturn}>
                  {t('platform.petition.return')}
                </button>
                {responseGet.data.state !== requestState.ACCEPTED && (
                  <button
                    className="health-data-button accept"
                    onClick={() => {
                      setRequestState(responseGet.data, requestState.ACCEPTED);
                    }}
                  >
                    {t('platform.petition.accept')}
                  </button>
                )}
                {(responseGet.data.state === requestState.NEW || responseGet.data.state === requestState.REJECTED) && (
                  <button
                    className="health-data-button moreInfo"
                    onClick={() => {
                      setRequestState(responseGet.data, requestState.MORE_INFO);
                    }}
                  >
                    {t('platform.petition.moreInfo')}
                  </button>
                )}
                {(responseGet.data.state === requestState.NEW || responseGet.data.state === requestState.MORE_INFO) && (
                  <button
                    className="health-data-button reject"
                    onClick={() => {
                      setRequestState(responseGet.data, requestState.REJECTED);
                    }}
                  >
                    {t('platform.petition.reject')}
                  </button>
                )}
              </div>
            </div>
            {loading && <LoadingPanel idPortal=".k-form" isFixed></LoadingPanel>}
          </>
        )}
      </div>
      {requestInConfirm && (
        <ManageRequestDialogConfirm
          requestId={requestInConfirm.request.id}
          datasetLink={requestInConfirm.request.datasetLink}
          applicantEmail={requestInConfirm.request.applicant.institutionalEmail}
          state={requestInConfirm.state}
          close={closeDialogConfirm}
        ></ManageRequestDialogConfirm>
      )}
    </div>
  );
};

export { RequestViewForm };
