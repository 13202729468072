import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { GridColumn, GridToolbar } from '@progress/kendo-react-grid';
import { urlBuilder } from '~/shared/constants';
import { CustomGrid } from '../shared/CustomGrid';
import { Input } from '@progress/kendo-react-inputs';
import { Button } from '@progress/kendo-react-buttons';
import { useAction, HttpMethod } from '~/hooks/useAction';
import { getCurrentLanguageLocale } from '~/services/app.i18n';
import { EditDatasetDialogForm } from './ManageDatasets/EditDatasetDialogForm';
import { DeleteDatasetDialogConfirm } from './ManageDatasets/DeleteDatasetDialogConfirm';
import { ManageDatasetsRow } from './ManageDatasets/ManageDatasetsRow';
import { requestState } from './ManageRequests/requestStates';
import { StatisticsStateNumberLabel } from './StatisticsStateNumberLabel';
import './manageDatasets.scss';

const ManageDatasets = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [productInEdit, setProductInEdit] = useState();
  const [productInDelete, setProductInDelete] = useState();

  const edit = dataItem => {
    history.push('/platform/manage-datasets/edit/' + dataItem.id);
  };

  const closeEditModal = reloadData => {
    setProductInEdit(undefined);
    if (reloadData) reload();
  };

  const closeDeleteModal = reloadData => {
    setProductInDelete(undefined);
    if (reloadData) reload();
  };

  const insert = () => {
    history.push('/platform/manage-datasets/new');
  };

  const [searchText, setSearchText] = useState('');

  const { executeAction: executeSearch, loading: loadingGrid, response: dataGrid } = useAction(
    {
      httpMethod: HttpMethod.GET
    },
    true,
    false
  );

  const onSearch = () => {
    executeSearch({ url: urlBuilder.datasets.getAdminDatasets(searchText, getCurrentLanguageLocale()) });
  };

  useEffect(() => {
    onSearch();
  }, [t]);

  const handleChange = event => {
    setSearchText(event.target.value);
  };

  const reload = () => {
    setSearchText('');
    onSearch();
  };

  return (
    <>
      <h1>{t('platform.manage.title')}</h1>
      <p>
        {t('platform.manage.subtitle1')}
        <i>{t('platform.manage.subtitle2')}</i>
        {t('platform.manage.subtitle3')}
        <i>{t('platform.manage.subtitle4')}</i>
        {t('platform.manage.subtitle5')}
        <i>{t('platform.manage.subtitle6')}</i>
        {t('platform.manage.subtitle7')}
      </p>
      <CustomGrid
        className={'grid-manage-datasets'}
        loading={loadingGrid}
        data={dataGrid && dataGrid.data}
        onRowClick={e => {
          edit(e.dataItem);
        }}
      >
        <GridToolbar>
          <div className="health-data-buttons-container">
            <button onClick={insert} className="health-data-button">
              {t('platform.manage.add')}
            </button>
          </div>
        </GridToolbar>
        <GridColumn
          field="name"
          title={t('platform.manage.columns.name')}
          cell={props => (
            <td className={'cell-selectable'} onClick={() => edit(props.dataItem)}>
              <span title={props.dataItem.name}>{props.dataItem.name}</span>
            </td>
          )}
        ></GridColumn>
        <GridColumn
          field="totalAccessPetitions"
          title={t(`platform.manage.columns.${requestState.NEW}`)}
          cell={props => (
            <td className={'cell-selectable'} onClick={() => edit(props.dataItem)}>
              <StatisticsStateNumberLabel
                state={requestState.NEW}
                total={props.dataItem.totalAccessPetitions}
              ></StatisticsStateNumberLabel>
            </td>
          )}
        ></GridColumn>
        <GridColumn
          field="newAccessPetitions"
          title={t(`platform.manage.columns.${requestState.ACCEPTED}`)}
          cell={props => (
            <td className={'cell-selectable'} onClick={() => edit(props.dataItem)}>
              <StatisticsStateNumberLabel
                state={requestState.ACCEPTED}
                total={props.dataItem.acceptedAccessPetitions}
              ></StatisticsStateNumberLabel>
            </td>
          )}
        ></GridColumn>
        <GridColumn
          field="requestedInfoAccessPetitions"
          title={t(`platform.manage.columns.${requestState.MORE_INFO}`)}
          cell={props => (
            <td className={'cell-selectable'} onClick={() => edit(props.dataItem)}>
              <StatisticsStateNumberLabel
                state={requestState.MORE_INFO}
                total={props.dataItem.requestedInfoAccessPetitions}
              ></StatisticsStateNumberLabel>
            </td>
          )}
        ></GridColumn>
        <GridColumn
          field="rejectedAccessPetitions"
          title={t(`platform.manage.columns.${requestState.REJECTED}`)}
          cell={props => (
            <td className={'cell-selectable'} onClick={() => edit(props.dataItem)}>
              <StatisticsStateNumberLabel
                state={requestState.REJECTED}
                total={props.dataItem.rejectedAccessPetitions}
              ></StatisticsStateNumberLabel>
            </td>
          )}
        ></GridColumn>
      </CustomGrid>
      {productInEdit && <EditDatasetDialogForm dataset={productInEdit} close={closeEditModal} />}
      {productInDelete && (
        <DeleteDatasetDialogConfirm dataset={productInDelete} close={closeDeleteModal}></DeleteDatasetDialogConfirm>
      )}
      <div className={'grid-mobile-manage-datasets'}>
        <p className="k-form container-search-datasets">
          <Input
            label={t('platform.manage.searchDatasets')}
            maxLength={255}
            value={searchText}
            onChange={handleChange}
          ></Input>
          <Button onClick={onSearch} icon="search"></Button>
          <Button className={'k-button-mobile'} onClick={insert}>
            {t('platform.manage.add')}
          </Button>
        </p>
        <CustomGrid
          className={'k-grid-mobile'}
          loading={loadingGrid}
          data={dataGrid && dataGrid.data}
          sortable={false}
          filterable={false}
        >
          <GridColumn
            cell={props => (
              <td onClick={() => edit(props.dataItem)}>
                <ManageDatasetsRow dataItem={props.dataItem}></ManageDatasetsRow>
              </td>
            )}
          ></GridColumn>
        </CustomGrid>
      </div>
    </>
  );
};

export { ManageDatasets };
