import React from 'react';
import { ToastProvider } from 'react-toast-notifications';
import { Container } from 'reactstrap';
import { Footer } from './Footer';
import './layout.scss';
import './button.scss';
import './customDialog.scss';
import './customForm.scss';
import './customDatepicker.scss';
import { NavPlatformMenu } from './NavPlatformMenu';

const Layout = ({ children }) => {
  return (
    <ToastProvider components={{ Toast: Notification }} autoDismiss={true} autoDismissTimeout={5000}>
      <NavPlatformMenu></NavPlatformMenu>
      <div className="health-data-container">
        <Container>{children}</Container>
      </div>
      <Footer></Footer>
    </ToastProvider>
  );
};

export { Layout };
