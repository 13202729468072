import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ConditionsNoticeViewer } from './ConditionsNoticeViewer';
import './conditionsNotice.scss';

export function ConditionsNotice() {
  const [t] = useTranslation();
  const [show, setShow] = useState(false);

  const showModal = () => {
    setShow(true);
  };

  const hideModal = () => {
    setShow(false);
  };

  return (
    <>
      <span className="legalNotice__link" onClick={showModal}>
        {t('common.conditionsNotice')}
      </span>

      <ConditionsNoticeViewer show={show} onClose={hideModal} />
    </>
  );
}
