import React from 'react';
import { useLocation } from 'react-router-dom';
import * as QueryString from 'query-string';
import { ConditionsViewer } from '../shared/ConditionsViewer';
import { LegalNoticeViewer } from '../shared/LegalNoticeViewer';
import { ConditionsNoticeViewer } from '../shared/ConditionsNoticeViewer';
import { Layout } from '../shared/Layout';

const redirectTo = location => {
  const params = QueryString.parse(location.search);
  window.location = params.returnUrl ?? '/';
};

const Privacy = () => {
  const location = useLocation();

  return (
    <Layout>
      <ConditionsViewer
        show={true}
        isPolicy={true}
        onClose={() => {
          redirectTo(location);
        }}
      ></ConditionsViewer>
    </Layout>
  );
};

const PrivacyRegister = () => {
  const location = useLocation();

  return (
    <Layout>
      <ConditionsViewer
        show={true}
        isPolicy={true}
        isRegister={true}
        onClose={() => {
          redirectTo(location);
        }}
      ></ConditionsViewer>
    </Layout>
  );
};

const Terms = () => {
  const location = useLocation();

  return (
    <Layout>
      <ConditionsViewer
        show={true}
        onClose={() => {
          redirectTo(location);
        }}
      ></ConditionsViewer>
    </Layout>
  );
};

const TermsRegister = () => {
  const location = useLocation();

  return (
    <Layout>
      <ConditionsViewer
        show={true}
        isRegister={true}
        onClose={() => {
          redirectTo(location);
        }}
      ></ConditionsViewer>
    </Layout>
  );
};

const LegalNotice = () => {
  const location = useLocation();

  return (
    <Layout>
      <LegalNoticeViewer
        show={true}
        onClose={() => {
          redirectTo(location);
        }}
      ></LegalNoticeViewer>
    </Layout>
  );
};

const ConditionsNotice = () => {
  const location = useLocation();

  return (
    <Layout>
      <ConditionsNoticeViewer
        show={true}
        onClose={() => {
          redirectTo(location);
        }}
      ></ConditionsNoticeViewer>
    </Layout>
  );
};

export { Privacy, PrivacyRegister, Terms, TermsRegister, LegalNotice, ConditionsNotice };
