import React from 'react';
import { Container } from 'reactstrap';
import { NavPlatformMenu } from './NavPlatformMenu';
import { Footer } from './Footer';
import './layout.scss';
import './button.scss';
import './customDialog.scss';
import './customForm.scss';
import './customDatepicker.scss';

const LayoutPlatformMenu = ({ children }) => {
  return (
    <div>
      <NavPlatformMenu></NavPlatformMenu>
      <div className="health-data-container">
        <Container>{children}</Container>
      </div>
      <Footer></Footer>
    </div>
  );
};

export { LayoutPlatformMenu };
