import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

const RequestDatasetResponseMessage = ({ idRequest }) => {
  const { t } = useTranslation();
  const history = useHistory();
  return (
    <div>
      <h1>
        {t('platform.requestDataset.responseTitle')} {idRequest}
      </h1>
      <p>{t('platform.requestDataset.responseMessage')}</p>
      <div className="k-form-buttons">
        <button
          className="health-data-button"
          onClick={() => {
            history.replace('/platform/datasets');
          }}
        >
          {t('platform.requestDataset.responseButton')}
        </button>
      </div>
    </div>
  );
};

export { RequestDatasetResponseMessage };
