import React from 'react';

const RequestViewFormFieldArea = ({ label, value, rows }) => {
  return (
    <div className="mb-3">
      <label className="custom-label">{label}</label>
      <textarea className="k-textarea" rows={rows || 4} value={value || ''} readOnly></textarea>
    </div>
  );
};

export { RequestViewFormFieldArea };
