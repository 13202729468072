import PropTypes from 'prop-types';
import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CloseIcon } from '~/assets/images/close.svg';
import './PdfViewer.scss';

export function PdfViewer(props) {
  const [t] = useTranslation();

  return (
    <>
      <Modal id="modal" {...props} size="lg" centered>
        <div className="pdfViewer">
          <div className="pdfViewer__header">
            <Modal.Title id="title" className="font-weight-bold">
              {t('common.modalHeaderPdfViewer')}
            </Modal.Title>
            <CloseIcon className="pdfViewer__icon" onClick={props.onClose} tabIndex={0} />
          </div>
          <div className="pdfViewer__content">
            <iframe className="desktop-pdf" type="application/pdf" title="viewer" src={props.filepath} />
            <iframe
              type="application/pdf"
              className="mobile-pdf"
              title="viewer"
              src={`https://docs.google.com/viewer?url=${window.location.origin}${props.filepath}&embedded=true`}
            />
          </div>
        </div>
      </Modal>
    </>
  );
}

PdfViewer.propTypes = {
  filepath: PropTypes.string,
  show: PropTypes.bool,
  onClose: PropTypes.func
};
