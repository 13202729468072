import React from 'react';

const ManageDatasetsRow = ({ dataItem }) => {
  return (
    <>
      <div className="grid-datasets__title" title={`${dataItem.name}`}>
        {`${dataItem.name}`}
      </div>
      <div className="grid-datasets__description" title={dataItem.description}>
        {dataItem.description}
      </div>
    </>
  );
};

export { ManageDatasetsRow };
