import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { GridColumn } from '@progress/kendo-react-grid';
import { Input } from '@progress/kendo-react-inputs';
import { Button } from '@progress/kendo-react-buttons';
import { urlBuilder } from '~/shared/constants';
import { CustomGrid } from '../shared/CustomGrid';
import { useAction, HttpMethod } from '~/hooks/useAction';
import { getCurrentLanguageLocale } from '~/services/app.i18n';
import { StateLabel } from './ManageRequests/StateLabel';
import StateFilterCell from './ManageRequests/StateFilterCell';
import { ManageRequestsRow } from './ManageRequests/ManageRequestsRow';
import { requestState } from './ManageRequests/requestStates';
import { ManageRequestDialogConfirm } from './ManageRequests/Dialogs/ManageRequestDialogConfirm';
import './manageRequests.scss';

const ManageRequests = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const states = [
    { id: `${requestState.NEW}`, name: t(`platform.petitions.states.${requestState.NEW}`) },
    { id: `${requestState.MORE_INFO}`, name: t(`platform.petitions.states.${requestState.MORE_INFO}`) },
    { id: `${requestState.ACCEPTED}`, name: t(`platform.petitions.states.${requestState.ACCEPTED}`) },
    { id: `${requestState.REJECTED}`, name: t(`platform.petitions.states.${requestState.REJECTED}`) }
  ];

  const [searchText, setSearchText] = useState('');

  const { executeAction: executeSearch, loading: loadingGrid, response: dataGrid } = useAction(
    {
      httpMethod: HttpMethod.GET
    },
    true,
    false
  );

  const onSearch = () => {
    executeSearch({ url: urlBuilder.petitions.getAccessPetitions(searchText, getCurrentLanguageLocale()) });
  };

  useEffect(() => {
    onSearch();
  }, [t]);

  const handleChange = event => {
    setSearchText(event.target.value);
  };

  const stateFilterCell = StateFilterCell(states, { id: -1, name: t(`platform.petitions.states.all`) }, 'name', 'id');

  const [requestInConfirm, setRequestInConfirm] = useState();

  const setRequestState = (request, state) => {
    setRequestInConfirm({ request, state });
  };

  const closeDialogConfirm = reloadData => {
    setRequestInConfirm(undefined);
    if (reloadData) onSearch();
  };

  return (
    <>
      <h1>{t('platform.petitions.title')}</h1>
      <p>{t('platform.petitions.subtitle')}</p>
      <CustomGrid
        loading={loadingGrid}
        data={dataGrid && dataGrid.data}
        className={'grid-manage-requests'}
        onRowClick={e => {
          history.push('/platform/manage-requests/' + e.dataItem.id);
        }}
      >
        <GridColumn className={'cell-selectable'} field="id" title={t('platform.petitions.columns.id')}></GridColumn>
        <GridColumn
          className={'cell-selectable'}
          field="applicantName"
          title={t('platform.petitions.columns.applicantName')}
        ></GridColumn>
        <GridColumn
          className={'cell-selectable'}
          field="datasetName"
          title={t('platform.petitions.columns.datasetName')}
        ></GridColumn>
        <GridColumn
          field="state"
          title={t('platform.petitions.columns.state')}
          filterCell={stateFilterCell}
          cell={props => (
            <td className={'cell-selectable'}>
              <StateLabel state={props.dataItem.state}></StateLabel>
            </td>
          )}
        ></GridColumn>
        <GridColumn
          field="created"
          title={t('platform.petitions.columns.created')}
          cell={props => (
            <td className={'cell-selectable'}>{String.stringToLocaleString(props.dataItem[props.field])}</td>
          )}
        ></GridColumn>
        <GridColumn
          title={t('platform.petitions.columns.actions')}
          sortable={false}
          filterable={false}
          cell={props => (
            <td>
              <div className="cell-container-buttons grid-icon-buttons">
                {props.dataItem.state !== requestState.ACCEPTED && (
                  <button
                    className="k-button k-button-accept k-button-icon"
                    title={t('platform.petitions.accept')}
                    onClick={() => {
                      setRequestState(props.dataItem, requestState.ACCEPTED);
                    }}
                  >
                    <span className={'k-icon k-i-check-outline'} />
                  </button>
                )}
                {(props.dataItem.state === requestState.NEW || props.dataItem.state === requestState.REJECTED) && (
                  <button
                    className="k-button k-button-moreinfo k-button-icon"
                    title={t('platform.petitions.requestMoreInfo')}
                    onClick={() => {
                      setRequestState(props.dataItem, requestState.MORE_INFO);
                    }}
                  >
                    <span className={'k-icon k-i-information'} />
                  </button>
                )}
                {(props.dataItem.state === requestState.NEW ||
                  props.dataItem.state === requestState.MORE_INFO ||
                  props.dataItem.state === requestState.ACCEPTED) && (
                  <button
                    className="k-button k-button-reject k-button-icon"
                    title={t('platform.petitions.reject')}
                    onClick={() => {
                      setRequestState(props.dataItem, requestState.REJECTED);
                    }}
                  >
                    <span className={'k-icon k-i-close-outline'} />
                  </button>
                )}
              </div>
            </td>
          )}
        ></GridColumn>
      </CustomGrid>
      <div className={'grid-mobile-manage-requests'}>
        <p className="k-form container-search-petitions">
          <Input
            label={t('platform.petitions.searchPetitions')}
            maxLength={255}
            value={searchText}
            onChange={handleChange}
          ></Input>
          <Button onClick={onSearch} icon="search"></Button>
        </p>
        <CustomGrid
          className={'k-grid-mobile'}
          loading={loadingGrid}
          data={dataGrid && dataGrid.data}
          sortable={false}
          filterable={false}
        >
          <GridColumn
            cell={props => (
              <td
                onClick={() => {
                  history.push('/platform/manage-requests/' + props.dataItem.id);
                }}
              >
                <ManageRequestsRow dataItem={props.dataItem}></ManageRequestsRow>
              </td>
            )}
          ></GridColumn>
        </CustomGrid>
      </div>
      {requestInConfirm && (
        <ManageRequestDialogConfirm
          requestId={requestInConfirm.request.id}
          datasetLink={requestInConfirm.request.datasetLink}
          applicantEmail={requestInConfirm.request.applicantInstitutionalEmail}
          state={requestInConfirm.state}
          close={closeDialogConfirm}
        ></ManageRequestDialogConfirm>
      )}
    </>
  );
};

export { ManageRequests };
