import React from 'react';
import { useTranslation } from 'react-i18next';
import { Field } from '@progress/kendo-react-form';
import { FormInput, FormDatePicker, FormDropDownList } from '~/components/shared/form-fields';
import { emailValidator } from '~/components/shared/form-validators';

const FormStudentElement = ({ typesID }) => {
  const { t } = useTranslation();

  return (
    <>
      <p className="health-data-subtitle">
        <b>{t('platform.requestDataset.titleStudent')}</b>
        <br></br>
        <span className="health-data-subtitle description">{t('platform.requestDataset.descriptionStudent')}</span>
      </p>
      <div className="mb-3">
        <Field name={'student.name'} component={FormInput} label={t('platform.requestDataset.fields.student.name')} />
      </div>
      <div className="mb-3">
        <Field name={'student.title'} component={FormInput} label={t('platform.requestDataset.fields.student.title')} />
      </div>
      <div className="mb-3">
        <Field
          name={'student.position'}
          component={FormInput}
          label={t('platform.requestDataset.fields.student.position')}
        />
      </div>
      <div className="mb-3">
        <Field
          name={'student.affiliation'}
          component={FormInput}
          label={t('platform.requestDataset.fields.student.affiliation')}
        />
      </div>
      <div className="mb-3">
        <Field
          name={'student.institutionalEmail'}
          component={FormInput}
          label={t('platform.requestDataset.fields.student.institutionalEmail')}
          validator={emailValidator}
        />
      </div>
      <div className="mb-3">
        <Field
          name={'student.address'}
          component={FormInput}
          label={t('platform.requestDataset.fields.student.address')}
        />
      </div>
      <fieldset className={'k-form-fieldset'}>
        <legend className={'k-form-legend'}>
          {t('platform.requestDataset.fields.student.identificationDocumentLegend')}
        </legend>
        <div className="mb-3">
          <Field
            key={'student.identificationDocument'}
            id={'student.identificationDocument'}
            name={'student.identificationDocument'}
            label={t('platform.requestDataset.fields.student.identificationDocument')}
            component={FormDropDownList}
            data={typesID}
            textField={'name'}
            valueField={'id'}
          />
        </div>
        <div className="mb-3">
          <Field
            name={'student.identificationNumber'}
            component={FormInput}
            label={t('platform.requestDataset.fields.student.identificationNumber')}
          />
        </div>
        <div className="mb-3">
          <Field
            name={'student.countryID'}
            component={FormInput}
            label={t('platform.requestDataset.fields.student.countryID')}
          />
        </div>
        <div className="mb-3">
          <Field
            name={'student.dateOfIssue'}
            component={FormDatePicker}
            label={t('platform.requestDataset.fields.student.dateOfIssue')}
          />
        </div>
        <div className="mb-3">
          <Field
            name={'student.dueDate'}
            component={FormDatePicker}
            label={t('platform.requestDataset.fields.student.dueDate')}
          />
        </div>
      </fieldset>
      <p className="health-data-subtitle notes">{t('platform.requestDataset.noteStudent')}</p>
    </>
  );
};

export { FormStudentElement };
