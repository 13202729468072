import React from 'react';
import { useTranslation } from 'react-i18next';
import { Layout } from '../shared/Layout';
import { Logos } from '../shared/Logos';
import playbook from '~/assets/images/escena_1.png';
import openHealth from '~/assets/images/escena_2.png';
import './index.scss';
import { Information } from '../../modules';

const Home = () => {
  const { t } = useTranslation();

  return (
    <Layout>
      <div className="landing-wrapper platform-wrapper container">
        <div className="box__content">
          <p className="text-center">
            <span className="landing-wrapper__slogan"> {t('landing.paragraph1_1')}</span>
          </p>
          <p className="text-center">
            {t('landing.paragraph1_2_1')}
            <a href="https://www.foundation29.org" target="_blank" rel="noopener noreferrer">
              {t('landing.paragraph1_2_2')}
            </a>
            {t('landing.paragraph1_2_3')}
            <span className="health-text">{t('landing.paragraph1_3_1')}</span>
            <span className="health-data-29-text">{t('landing.paragraph1_3_2')}</span>
            {t('landing.paragraph1_4')}
          </p>
          <p className="text-center">{t('landing.paragraph2_1')}</p>
          <p className="text-center">{t('landing.paragraph3_1')}</p>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="landing-wrapper__title health-data-title__big">{t('landing.playbook')}</div>
            <div className="landing-wrapper__img box-buttons">
              <img alt="logo" src={playbook} />
              <div className="buttons-wrapper">
                <a href="/playbook">
                  <button id="btnvisitPlaybook" className="buttons-wrapper__item">
                    {t('landing.visitPlaybook')}
                  </button>
                </a>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="landing-wrapper__title health-data-title__big">{t('landing.platform')}</div>
            <div className="landing-wrapper__img box-buttons">
              <img alt="logo" src={openHealth} />
              <div className="buttons-wrapper">
                <a href="/platform">
                  <button id="btnvisitPlatform" className="buttons-wrapper__item">
                    {t('landing.visitPlatform')}
                  </button>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-12">
          <p className="text-center">
            {t('landing.paragraph4_1')}
            <a href="https://www.foundation29.org" target="_blank" rel="noopener noreferrer">
              {t('landing.paragraph4_2')}
            </a>
            {t('landing.paragraph4_3')}
          </p>
        </div>
        <Information />
        <Logos />
      </div>
    </Layout>
  );
};

export { Home };
