import React from 'react';
import { IntlProvider, load, LocalizationProvider, loadMessages } from '@progress/kendo-react-intl';
import { getCurrentLanguageCode, getCurrentLanguageLocale } from '~/services/app.i18n';

import likelySubtags from 'cldr-core/supplemental/likelySubtags.json';
import currencyData from 'cldr-core/supplemental/currencyData.json';
import weekData from 'cldr-core/supplemental/weekData.json';

import numbers from 'cldr-numbers-full/main/es/numbers.json';
import currencies from 'cldr-numbers-full/main/es/currencies.json';
import caGregorian from 'cldr-dates-full/main/es/ca-gregorian.json';
import dateFields from 'cldr-dates-full/main/es/dateFields.json';
import timeZoneNames from 'cldr-dates-full/main/es/timeZoneNames.json';
import esMessages from '~/assets/locales/kendo.es.json';

load(likelySubtags, currencyData, weekData, numbers, currencies, caGregorian, dateFields, timeZoneNames);

loadMessages(esMessages, 'es-ES');

const KendoLocalizationProvider = ({ children }) => {
  return (
    <LocalizationProvider language={getCurrentLanguageCode()}>
      <IntlProvider locale={getCurrentLanguageLocale()}>{children}</IntlProvider>
    </LocalizationProvider>
  );
};

export { KendoLocalizationProvider };
